import React from 'react';
import {useHomepageContextProvider} from '../../state';
import {CmsBlocks} from './assets/CmsBlocks';
import {CategoryCards} from "./assets/CategoryCards";
import {LoadingIcon} from "../../../../general/assets/LoadingIcon";
import {CustomMarquee} from './assets/CustomMarquee';

export const HomeMainContent: React.FC = () => {
    const {categoriesData, loading} = useHomepageContextProvider();

    const selection1: (string | number)[] = [14, 13, 12, 5];
    const selection2: (string | number)[] = [15, 16, 17];
    const selection3: (string | number)[] = [4, 7, 8];

    if (loading) {
        return <LoadingIcon/>;
    }

    const getSelectedCategories = (ids: (string | number)[]) => {
        if (!categoriesData || categoriesData.length === 0) {
            return [];
        }

        const findCategoriesRecursively = (categories: any[]): any[] => {
            let result: any[] = [];
            for (const category of categories) {
                if (ids.includes(category.id) || ids.includes(Number(category.id))) {
                    result.push(category);
                }
                if (category.children && category.children.length > 0) {
                    result = result.concat(findCategoriesRecursively(category.children));
                }
            }
            return result;
        };

        return findCategoriesRecursively(categoriesData);
    };

    return (
        <div className="homepage flex flex-col mt-5 xl:w-4/6">
            <CmsBlocks/>
            <div className="flex flex-col w-full">
                <div className="mb-2">
                    <CustomMarquee
                        text="Testează-ți cunoștințele pentru clasele V-VIII!"
                        speed={50}
                        gradient={false}
                    />
                    <CategoryCards categories={getSelectedCategories(selection1)}/>
                </div>
                <div className="mb-2">
                    <CustomMarquee
                        text="Explorează testele pentru diferite niveluri de dificultate!"
                        speed={50}
                        gradient={false}
                    />
                    <CategoryCards categories={getSelectedCategories(selection2)}/>
                </div>
                <div className="mb-2">
                    <CustomMarquee
                        text="Descoperă testele pentru evaluarea națională și alte tipuri de teste!"
                        speed={50}
                        gradient={false}
                    />
                    <CategoryCards categories={getSelectedCategories(selection3)}/>
                </div>
            </div>
        </div>
    );
};
