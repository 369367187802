import React, {useState, useEffect, useMemo} from 'react';
import {useProductAttributes} from '../../../../state';
import {TestResult} from "../assets/TestResult";
import {useWindowSize, SaveTestBtn, ErrorMessage} from "../../../../../../general";

interface Answer {
    id: number;
    words: string[];
}

interface Question {
    id: number;
    text: string;
    answers: Answer[];
}

interface MultipleAnswerSubpunct {
    id: number;
    text: string;
    subpuncte: Question[];
}

function decodeHtml(html: string) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

const removeDiacritics = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const MultipleAnswer = () => {
    const {
        testData,
        loading,
        saveTestData,
        productSku,
        mutationError,
        clearMutationError,
        isTestCompleted,
        testResult,
        setTestCompleted
    } = useProductAttributes() || {};

    const [userAnswers, setUserAnswers] = useState<{ [key: string]: string }>({});
    const [saveMessage, setSaveMessage] = useState<string | null>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const decodedTestData = useMemo(() => {
        if (!testData || !testData.multipleAnswer) return null;

        return {
            multipleAnswer: testData.multipleAnswer.map(item => ({
                ...item,
                text: decodeHtml(item.text),
                subpuncte: item.subpuncte.map(question => ({
                    ...question,
                    text: decodeHtml(question.text)
                }))
            }))
        };
    }, [testData]);

    useEffect(() => {
        if (decodedTestData && decodedTestData.multipleAnswer) {
            const initialAnswers = {};
            decodedTestData.multipleAnswer.forEach(item => {
                item.subpuncte.forEach(question => {
                    question.answers.forEach(answer => {
                        initialAnswers[`${item.id}-${question.id}-${answer.id}`] = '';
                    });
                });
            });
            setUserAnswers(initialAnswers);
        }
    }, [decodedTestData]);

    const handleAnswerChange = (itemId: number, questionId: number, answerId: number, value: string) => {
        const key = `${itemId}-${questionId}-${answerId}`;
        setUserAnswers(prev => ({
            ...prev,
            [key]: value.trim()
        }));

        // Șterge eroarea pentru acest input
        if (errors[key]) {
            setErrors(prev => {
                const newErrors = {...prev};
                delete newErrors[key];
                return newErrors;
            });
        }

        // Verifică dacă răspunsurile sunt identice
        const otherAnswerId = answerId === 1 ? 2 : 1;
        const otherKey = `${itemId}-${questionId}-${otherAnswerId}`;
        if (removeDiacritics(value.trim().toLowerCase()) === removeDiacritics(userAnswers[otherKey]?.toLowerCase())) {
            setErrors(prev => ({
                ...prev,
                [key]: 'Răspunsurile nu pot fi identice'
            }));
        } else {
            setErrors(prev => {
                const newErrors = {...prev};
                delete newErrors[key];
                delete newErrors[otherKey];
                return newErrors;
            });
        }
    };

    const handleSave = async () => {
        const newErrors = {};
        decodedTestData.multipleAnswer.forEach(item => {
            item.subpuncte.forEach(question => {
                question.answers.forEach(answer => {
                    const key = `${item.id}-${question.id}-${answer.id}`;
                    if (!userAnswers[key] || userAnswers[key].trim() === '') {
                        newErrors[key] = 'Introduceți un răspuns';
                    }
                });
            });
        });

        setErrors(prevErrors => ({...prevErrors, ...newErrors}));

        if (Object.keys(newErrors).length > 0) {
            setSaveMessage('Te rugăm să răspunzi la toate întrebările înainte de a finaliza testul.');
            return;
        }

        if (Object.keys(errors).length > 0) {
            setSaveMessage('Vă rugăm să corectați erorile înainte de a salva.');
            return;
        }

        setIsSaving(true);
        clearMutationError();

        try {
            const testType = 'multiple_answer';

            const userTestData = decodedTestData.multipleAnswer.map(item => ({
                ...item,
                subpuncte: item.subpuncte.map(question => ({
                    ...question,
                    answers: question.answers.map(answer => ({
                        ...answer,
                        user_answer: userAnswers[`${item.id}-${question.id}-${answer.id}`] || '',
                        right_answer: answer.words
                    }))
                }))
            }));

            const testDataToSave = {
                multipleAnswer: userTestData,
                timestamp: new Date().toISOString()
            };

            const score = calculateScore(userAnswers, decodedTestData.multipleAnswer);

            const result = await saveTestData({
                product_sku: productSku,
                test_type: testType,
                test_data: JSON.stringify(testDataToSave),
                test_score: score
            });

            if (result) {
                setSaveMessage('Testul a fost salvat!');
                setTestCompleted(true, { score: score });
            } else {
                setSaveMessage(null);
            }
        } catch (error) {
            console.error('Eroare la salvarea răspunsurilor:', error);
            setSaveMessage(null);
        } finally {
            setIsSaving(false);
        }
    };

    const normalizeAnswer = (answer: string): string => {
        return removeDiacritics(answer.replace(/\s+/g, ' ').trim().toLowerCase());
    };

    const calculateScore = (userAnswers: { [key: string]: string }, questions: MultipleAnswerSubpunct[]): number => {
        let totalScore = 0;
        let totalQuestions = 0;

        questions.forEach(item => {
            item.subpuncte.forEach(question => {
                totalQuestions++;
                let questionScore = 0;

                const answers = question.answers;
                for (let i = 0; i < answers.length; i += 2) {
                    const answer1 = answers[i];
                    const answer2 = i + 1 < answers.length ? answers[i + 1] : null;

                    const key1 = `${item.id}-${question.id}-${answer1.id}`;
                    const userAnswer1 = normalizeAnswer(userAnswers[key1] || '');
                    const isCorrect1 = answer1.words.some(word => normalizeAnswer(word) === userAnswer1);

                    let isCorrect2 = false;
                    if (answer2) {
                        const key2 = `${item.id}-${question.id}-${answer2.id}`;
                        const userAnswer2 = normalizeAnswer(userAnswers[key2] || '');
                        isCorrect2 = answer2.words.some(word => normalizeAnswer(word) === userAnswer2);
                    }

                    if (isCorrect1 && isCorrect2) {
                        questionScore += 1;
                    } else if (isCorrect1 || isCorrect2) {
                        questionScore += 0.5;
                    }
                }

                totalScore += questionScore;
            });
        });

        return Number(((totalScore / totalQuestions) * 100).toFixed(2));
    };

    if (!decodedTestData || !decodedTestData.multipleAnswer || decodedTestData.multipleAnswer.length === 0) {
        return null;
    }

    if (isTestCompleted && testResult) {
        return (
            <TestResult
                score={testResult.score}
                saveMessage={saveMessage}
            />
        );
    }

    return (
        <div className="multiple-answer-container max-w-[800px] flex flex-col gap-3 mt-5 mb-10 md:mb-15 p-3 md:p-6 border-2 border-double border-medium-blue shadow-round-shadow rounded-xl border-x border-y">
            <div className="flex flex-col items-start justify-start gap-3">
                <h2 className="uppercase font-font-p font-semibold text-2xl text-text-color">
                    Test cu raspunsuri multimple
                </h2>
                <p className="font-font-p font-semibold text-text-color text-xl text-text-color">
                    Citeste cu atentie si completeaza testul de mai jos pentru a putea raspunde la cerintele formulate:
                </p>
            </div>
            {decodedTestData.multipleAnswer.map((item: MultipleAnswerSubpunct) => (
                <div key={item.id} className="multiple-answer-item w-full mb-6 flex flex-col items-start justify-start gap-5">
                    <p dangerouslySetInnerHTML={{__html: item.text}} className="font-font-s font-normal text-xl text-text-color bg-white p-3 border-2 border-double border-medium-blue rounded-xl border-x border-y" />
                    <p className="font-font-p font-semibold text-xl text-text-color">
                        Cerinte:
                    </p>
                    {item.subpuncte.map((question) => (
                        <div key={question.id} className="question w-full p-3 flex flex-col items-start justify-start gap-3 border-2 border-double border-medium-blue rounded-xl border-x border-y">
                            <p className="font-font-s text-lg italic font-bold ">
                                Intrebarea {question.id}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: question.text}} className="font-font-s font-normal text-text-color text-xl bg-white p-3 border-2 border-double border-medium-blue rounded-xl border-x border-y" />
                            <p className="font-font-s text-lg italic font-bold ">
                                Raspunsurile:
                            </p>
                            <div className="answers font-font-s font-normal text-text-color text-xl flex flex-col md:flex-row gap-5 w-full">
                                {question.answers.map(answer => (
                                    <div key={answer.id} className="w-full md:w-1/3">
                                        <input
                                            className="border-2 border-gray-300 p-2 w-full rounded-xl"
                                            type="text"
                                            value={userAnswers[`${item.id}-${question.id}-${answer.id}`] || ''}
                                            onChange={(e) => handleAnswerChange(item.id, question.id, answer.id, e.target.value)}
                                            placeholder={`Răspuns ${answer.id}`}
                                        />
                                        {errors[`${item.id}-${question.id}-${answer.id}`] && (
                                            <p className="error-message text-red-500 font-font-s text-xl">
                                                {errors[`${item.id}-${question.id}-${answer.id}`]}
                                            </p>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
            <div className="flex justify-center items-center flex-col">
                <SaveTestBtn
                    onClick={handleSave}
                    disabled={Object.keys(errors).length > 0}
                    isSaving={isSaving}
                />
                {saveMessage && <p className="save-message mt-2 text-olive-color font-font-s text-xl">{saveMessage}</p>}
                {mutationError && <ErrorMessage message={mutationError}/>}
            </div>
        </div>
    );
};
