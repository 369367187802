import React, {createContext, useState, useEffect, useContext, ReactNode} from 'react';
import {request} from '../../../Infrastructure';
import {getCmsBlock, getCategories} from '../../queries';
import {useQuery} from "@tanstack/react-query";
import useLocalStorageState from 'use-local-storage-state'

declare global {
    interface Window {
        BASE_URL: string;
    }
}

interface CmsBlockItem {
    identifier: string;
    title: string;
    content: string;
}

interface SubCategory {
    id: string;
    name: string;
    url_path: string;
    children: SubCategory[];
}

interface Category {
    id: string;
    name: string;
    url_path: string;
    children?: Category[];
}

interface StateContextProps {
    blockData1: CmsBlockItem | null;
    blockData2: CmsBlockItem | null;
    blockData3: CmsBlockItem | null;
    blockData4: CmsBlockItem | null;
    categoriesData: Category[] | null;
    firstLevelCategories: string[];
    loading: boolean;
    error: any;
}

const HomepageContext = createContext<StateContextProps | undefined>(undefined);

export const HomepageProvider: React.FC<{
    children: ReactNode;
    cmsBlockIdentifiers: {
        block1: string;
        block2: string;
        block3: string;
        block4: string;
    };
}> = ({ children, cmsBlockIdentifiers }) => {
    const [blockData1, setBlockData1] = useState<CmsBlockItem | null>(null);
    const [blockData2, setBlockData2] = useState<CmsBlockItem | null>(null);
    const [blockData3, setBlockData3] = useState<CmsBlockItem | null>(null);
    const [blockData4, setBlockData4] = useState<CmsBlockItem | null>(null);
    const [categoriesData, setCategoriesData] = useState<Category[]>([]);
    const [firstLevelCategories, setFirstLevelCategories] = useState<string[]>([]);
    const [localData, setLocalData] = useLocalStorageState('homepage_data', { defaultValue: null });
    const categoryId = '2';
    const timeToRefresh = 15 * 60 * 1000; // 30 minutes

    const isValidData = (data) => {
        if (!data || !data.timestamp) return false;
        const now = new Date().getTime();
        const elapsed = now - data.timestamp;
        return elapsed < timeToRefresh;
    };

    const cmsBlockQuery = useQuery({
        queryKey: ['cmsBlocks', cmsBlockIdentifiers],
        queryFn: async () => {
            return request(getCmsBlock, {
                identifier1: cmsBlockIdentifiers.block1,
                identifier2: cmsBlockIdentifiers.block2,
                identifier3: cmsBlockIdentifiers.block3,
                identifier4: cmsBlockIdentifiers.block4
            });
        },
        enabled: true,
        staleTime: 900000,
    });

    useEffect(() => {
        if (cmsBlockQuery.data) {
            setBlockData1(cmsBlockQuery.data.data.block1.items[0] || null);
            setBlockData2(cmsBlockQuery.data.data.block2.items[0] || null);
            setBlockData3(cmsBlockQuery.data.data.block3.items[0] || null);
            setBlockData4(cmsBlockQuery.data.data.block4.items[0] || null);
        }
    }, [cmsBlockQuery.data]);

    const categoriesDataQuery = useQuery({
        queryKey: ['categoriesData'],
        queryFn: async () => {
            return request(getCategories, {
                categoryId
            });
        },
        initialData: localData && isValidData(localData) ? localData.data : undefined,
        enabled: !localData || !isValidData(localData),
        onSuccess: (response) => {
            const categoryList = response.data.categories.items[0].children;
            const formattedData = {
                data: categoryList,
                timestamp: new Date().getTime()
            };
            setLocalData(formattedData);
            setCategoriesData(categoryList);
        }
    });

    useEffect(() => {
        if (localData && isValidData(localData)) {
            setCategoriesData(localData.data);
        }
    }, [localData]);

    useEffect(() => {
        const newFirstLevelCategories = categoriesData.map(category => category.name);
        setFirstLevelCategories(newFirstLevelCategories);
    }, [categoriesData]);

    return (
        <HomepageContext.Provider
            value={{
                firstLevelCategories,
                categoriesData,
                blockData1,
                blockData2,
                blockData3,
                blockData4,
                loading: cmsBlockQuery.isLoading || categoriesDataQuery.isLoading,
                error: cmsBlockQuery.error || categoriesDataQuery.error
            }}>
            {children}
        </HomepageContext.Provider>
    );
};

export const useHomepageContextProvider = () => {
    const context = useContext(HomepageContext);
    if (!context) {
        throw new Error('useHomepageContextProvider must be used within a HomepageContext');
    }
    return context;
};
