import React, {useState, useEffect} from 'react';
import {useCategoriesAndProductsContextProvider} from '../../state';

declare global {
    interface Window {
        BASE_URL: string;
    }
}

export const CategoryButton: React.FC = () => {
    const {firstLevelCategories, categoriesData, loading} = useCategoriesAndProductsContextProvider();
    const [selectedCategory, setSelectedCategory] = useState<any | null>(null);

    const findCustomAttributeValue = (customAttributes, attributeCode) => {
        const attribute = customAttributes.find(attr => attr.attribute_code === attributeCode);
        return attribute ? attribute.value : null;
    };

    const handleCategoryClick = (categoryName) => {
        const category = categoriesData.find(cat => cat.name === categoryName);
        setSelectedCategory(category || null);
    };

    useEffect(() => {
        if (firstLevelCategories.length > 0 && categoriesData) {
            const initialCategory = categoriesData?.find(cat => cat.name === firstLevelCategories[0]);
            setSelectedCategory(initialCategory);
        }
    }, [firstLevelCategories, categoriesData]);

    if (loading)
        return (
            <div
                className={'react-css fixed top-0 left-0 w-full h-full flex flex-col text-white items-center justify-center bg-black bg-opacity-50 z-50'}>
                <span className={'react-css text-white text-center text-3xl font-bold'}>
                    {'Se incarca promotiile!'}
                </span>
            </div>
        );

    return (
        <div>
            <div className={'grid grid-cols-2 md:grid-cols-4 md:grid-rows-2 gap-5 justify-items-center'}>
                {firstLevelCategories.map((categoryName) => (
                    <button
                        key={categoryName}
                        onClick={() => handleCategoryClick(categoryName)}
                        className={'bg-main-orange focus:outline-none focus:bg-main-orange active:bg-main-orange ' +
                            'text-black text-2xl font-inter font-bold p-5 rounded-xl w-11/12 ' +
                            'hover:bg-main-orange hover:text-white hover:shadow-first-btn-shadow hover:scale-105 ' +
                            'transition-all duration-300 ease-in-out'}
                    >
                        {categoryName}
                    </button>
                ))}
            </div>
            <div className={'mt-5'}>
                {selectedCategory?.children?.map((subCategory) => (
                    <div key={subCategory.id}>
                        {subCategory?.products?.items?.length > 0 ? (
                            <h2
                                className={'react-css text-center font-bold font-inter text-5xl text-white tracking-wide p-5 mt-10 mb-7'}
                            >
                                {subCategory.name}
                            </h2>
                        ) : null}
                        {/* Show products of each subcategory */}
                        <div className={'grid grid-cols-2 md:grid-cols-4 gap-6'}>
                            {subCategory?.products?.items?.map((product) => (
                                <div key={product.id}
                                     className={'bg-white rounded-xl p-4 shadow mb-5 flex justify-between items-center flex-col h-auto ' +
                                         'hover:shadow-first-btn-shadow transition-all hover:border border-slate-400 duration-300 ease-in-out ' +
                                         'grid grid-row-2 p-5 relative'}>
                                    {
                                        findCustomAttributeValue(product?.custom_attributes, 'is_in_stock') === "Out of Stock" && (
                                            <div
                                                className={'absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 z-10'}>
                                                <span
                                                    className={'react-css text-rose-700 text-2xl font-black mb-48'}>
                                                    {'STOC EPUIZAT'}
                                                </span>
                                            </div>
                                        )
                                    }
                                    <div className={'react-css absolute m-3 left-0 top-0 flex flex-col gap-3 w-auto'}>
                                        {product?.price_range?.minimum_price?.regular_price.value !== product?.price_range?.minimum_price?.final_price.value ? (
                                            <div className={'react-css flex flex-col w-[80px]'}>
                                                <p className={'h-[20px] rounded-t text-white text-xl font-bold text-center bg-custom-red ' +
                                                    'flex items-center justify-center'}>
                                                    {`reducere`}
                                                </p>
                                                <p className={'h-[20px] rounded-b text-white text-xl font-bold text-center bg-custom-red ' +
                                                    'flex items-center justify-center'}>
                                                    {`- ${(
                                                        ((product?.price_range?.minimum_price?.regular_price.value - product?.price_range?.minimum_price?.final_price.value) /
                                                            (product?.price_range?.minimum_price?.regular_price.value || 1)) * 100
                                                    ).toFixed(0)}%`}
                                                </p>
                                            </div>
                                        ) : null}

                                        {product?.special_text_promo &&
                                        new Date(product?.text_to_date) >= new Date() &&
                                        new Date(product?.text_from_date) <= new Date() ? (
                                            <div className={'react-css flex flex-col w-[80px]'}>
                                                <p className={'react-css h-[30px] rounded-xl text-black text-xl font-bold text-center bg-first-btn-bg mt-3 ' +
                                                    'flex items-center justify-center'}>
                                                    {`+ cadou`}
                                                </p>
                                            </div>
                                        ) : null}
                                    </div>
                                    <a className={'row-span-1 h-[230px] flex justify-center items-center w-auto'}
                                       href={`${window.BASE_URL}${product.url_rewrites[0]?.url || ''}`}>
                                        <img src={product?.thumbnail?.url} alt={product?.thumbnail?.label}
                                             className={'w-auto object-contain object-center h-3/4'} loading="lazy"/>
                                    </a>
                                    <div className={'row-span-1 grid grid-row-4 gap-4 h-[250px] w-auto'}>
                                        <a
                                            className={'h-[90px] text-black hover:underline active:text-black'}
                                            href={`${window.BASE_URL}${product?.url_rewrites[0]?.url || ''}`}
                                        >
                                            <h2 className={'react-css text-2xl font-bold font-inter leading-10 mt-4 text-black'}>
                                                {product?.name.length > 45 ? `${product?.name.substring(0, 45)}...` : product?.name}
                                            </h2>
                                        </a>
                                        <div className={''}></div>
                                        <div className={'mt-2 h-[60px]'}>
                                            {product?.price_range?.minimum_price?.regular_price.value === product?.price_range?.minimum_price?.final_price.value ||
                                            !product?.special_price ? (
                                                <p
                                                    className={'react-css text-2xl font-bold'}>
                                                    {parseFloat(String(product?.price_range?.minimum_price?.regular_price.value)).toFixed(2)} {'RON'}
                                                </p>
                                            ) : (
                                                <div>
                                                    <p
                                                        className={'react-css text-xl font-semibold line-through'}>
                                                        {parseFloat(String(product?.price_range?.minimum_price?.regular_price.value)).toFixed(2)} {'RON'}
                                                    </p>
                                                    <p
                                                        className={'react-css text-2xl font-bold text-red-500'}>
                                                        {parseFloat(String(product?.price_range?.minimum_price?.final_price.value)).toFixed(2)} {'RON'}
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                        <button
                                            className={'h-[50px] bg-main-orange focus:outline-none focus:bg-main-orange active:bg-main-orange ' +
                                                'text-black text-2xl font-inter font-bold p-5 rounded-xl md:w-auto ' +
                                                'hover:bg-main-orange hover:text-white transition-all duration-300 ease-in-out'}
                                            onClick={() => window.location.href = `${window.BASE_URL}${product?.url_rewrites[0]?.url || ''}`}
                                        >
                                            {'Vezi detalii'}
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
