import React from 'react';
import {ProductAttributeProvider} from '../../state';
import {Description} from "./ProductDetails/Description";
import {Review} from './ProductDetails/Review';
import {ReviewForm} from './ProductDetails/ReviewForm';
import {useWindowSize} from '../../../../general';
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";
import {TestComponents} from "./ProductDetails/TestComponents";
import {AutoRelated} from "./ProductDetails/AutoRelated";
import {CheckoutProvider} from "../../../Checkout";

interface ProductSku {
    productSku: string;
}

const queryClient = client();

export const ProductDetails: React.FC<ProductSku> = ({productSku}) => {
    const {isMobile} = useWindowSize();

    return (
        <QueryClientProvider client={queryClient}>
            <CheckoutProvider>
                <ProductAttributeProvider productSku={productSku} config={{}}>
                    <div className={'w-full md:max-w-[1440px] flex flex-col justify-center items-center'}>
                        <Description/>
                        <TestComponents/>
                        <Review/>
                        <ReviewForm productSku={productSku}/>
                        <AutoRelated/>
                    </div>
                </ProductAttributeProvider>
            </CheckoutProvider>
        </QueryClientProvider>
    );
};
