import React from 'react';
import {FacebookIcon, InstagramIcon, YouTubeIcon} from '../../../../../general';

export const SocialLinks = () => {

    return (
        <div className={'w-full flex flex-col items-center justify-center'}>

            <div className="flex flex-row gap-10">
                {/*<a*/}
                {/*    href="https://www.youtube.com/"*/}
                {/*    title="Youtube"*/}
                {/*>*/}
                {/*    <YouTubeIcon size={'w-10 h-10'}/>*/}
                {/*</a>*/}
                {/*<a*/}
                {/*    href="https://www.facebook.com/"*/}
                {/*    title="Facebook"*/}
                {/*>*/}
                {/*    <FacebookIcon size={'w-10 h-10'}/>*/}
                {/*</a>*/}
                {/*<a*/}
                {/*    href="https://www.instagram.com/"*/}
                {/*    title="Instagram"*/}
                {/*>*/}
                {/*    <InstagramIcon size={'w-10 h-10'}/>*/}
                {/*</a>*/}
            </div>
            <div className={'mt-[60px] text-left text-text-color font-font-s text-2xl'}>
                {'©'} {new Date().getFullYear()} - Hint One Zero
            </div>
        </div>
    );
};
