import React from 'react';
import { TestFeedback } from './TestFeedback';

export const TestResult = ({ score, saveMessage }) => {
    return (
        <div className="test-result flex flex-col items-center gap-3">
            <h2 className={''}>Test Completat</h2>
            <p className={''}>Scorul tău: {score.toFixed(2)} din 100</p>
            <TestFeedback score={score} />
            <p>{saveMessage}</p>
        </div>
    );
};
