import React from 'react';

export const ImageDisplay = ({ imageName, altText, width, height, className }) => {
    const basePath = '/static/frontend/Hint4/theme/ro_RO/images/';

    const images = {
        background1: `${basePath}background1.png`,
        background2: `${basePath}background2.png`,
        minion1: `${basePath}happy-minion.jpg`,
        // Adăugați aici alte imagini după necesitate
    };

    const imageUrl = images[imageName];

    if (!imageUrl) {
        console.error(`Image ${imageName} not found`);
        return null;
    }

    return (
        <div className={`image-container ${className || ''}`}>
            <img
                src={imageUrl}
                alt={altText}
                width={width}
                height={height}
                className="responsive-image"
            />
        </div>
    );
};
