import React from 'react';
import {TextEditorConfig} from '../assets/TextEditorConfig';

export const TrueOrFalse = ({subpunct, onChange}) => {

    const handleTextChange = (content) => {
        onChange({...subpunct, text: content});
    };

    const handleQuestionChange = (questionId, field, value) => {
        const updatedQuestions = subpunct.questions.map(i =>
            i.id === questionId ? {...i, [field]: value} : i
        );
        onChange({...subpunct, questions: updatedQuestions});
    };

    const renderQuestion = (question) => (
        <div key={question.id} className="flex flex-col gap-6 w-full mb-8 border-b-2 border-light-gr border-dashed ">
            <p className="text-xl font-semibold">
                Intrebarea {question.id}
            </p>
            <TextEditorConfig
                value={question.text}
                onEditorChange={(content) => handleQuestionChange(question.id, 'text', content)}
                placeholder={`Introduceți textul pentru întrebarea ${question.id}`}
            />
            <div className="flex flex-row">
                <label className="flex items-center">
                    <input
                        type="radio"
                        checked={question.answer === true}
                        onChange={() => handleQuestionChange(question.id, 'answer', true)}
                    />
                    <span className="ml-2">Adevărat</span>
                </label>
                <label className="flex items-center">
                    <input
                        type="radio"
                        checked={question.answer === false}
                        onChange={() => handleQuestionChange(question.id, 'answer', false)}
                    />
                    <span className="ml-2">Fals</span>
                </label>
            </div>
        </div>
    );

    return (
        <div className="flex flex-col gap-8 items-start justify-start w-full pb-8">
            <p className="text-2xl font-semibold">
                Introduceti textul initial
            </p>
            <TextEditorConfig
                value={subpunct.text}
                onEditorChange={handleTextChange}
                placeholder={"Introduceți textul pentru acest subpunct"}
            />
            <p className="text-2xl font-semibold">
                Intrebari
            </p>
            <div className="grid grid-cols-2 gap-10 w-full">
                {subpunct.questions.map(renderQuestion)}
            </div>
        </div>
    );
};
