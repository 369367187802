import React from 'react';
import {useProductAttributes} from '../../../state';
import {MultiChoose} from "./testTypes/MultiChoose";
import {TrueOrFalse} from "./testTypes/TrueOrFalse";
import {MultipleAnswer} from "./testTypes/MultipleAnswer";
import {AnimatedLoginPrompt} from "./assets/AnimatedLoginPrompt";
import {SubscriptionAccessDenied} from "./assets/SubscriptionAccessDenied";
import {DescriptionSk} from "../Skeleton/DescriptionSk";

export const TestComponents = () => {
    const {testData, loading, isInTestCategory} = useProductAttributes();

    if (loading) {
        return <DescriptionSk/>;
    }

    if (isInTestCategory) {
        if (testData === 'userNotAuthenticated') {
            return <AnimatedLoginPrompt />;
        }

        if (testData === 'noSubscriptionAccess') {
            return <SubscriptionAccessDenied />;
        }
    }

    if (isInTestCategory || (testData && testData !== 'userNotAuthenticated' && testData !== 'noSubscriptionAccess')) {
        return (
            <>
                <MultiChoose />
                <MultipleAnswer />
                <TrueOrFalse />
            </>
        );
    }

    return null;
};
