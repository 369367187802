import React, {createContext, useState, useContext, useEffect, ReactNode} from 'react';
import {request} from '../../../Infrastructure';
import {useQuery} from "@tanstack/react-query";
import {getCategories} from '../../queries';

interface CategoryData {
    id: number;
    name: string;
    url_key: string;
    description: string | null;
    image: string | null;
    children: {
        id: number;
        name: string;
        url_path: string;
        description: string | null;
        image: string | null;
    }[];
}

interface CategoryContextValue {
    categoryId: string;
    categoryData: CategoryData | null;
    loading: boolean;
    error: any;
}

const CategoryContext = createContext<CategoryContextValue | undefined>(undefined);

export const CategoryProvider: React.FC<{ children: ReactNode; categoryId: string }> = (
    {
        children,
        categoryId,
    }) => {
    const {data, isLoading, error} = useQuery({
        queryKey: ['category', categoryId],
        queryFn: async () => {
            return request(getCategories, {id: parseInt(categoryId, 10)});
        },
        enabled: !!categoryId,
        refetchOnWindowFocus: false,
    });

    const categoryData = data?.data?.category || null;

    return (
        <CategoryContext.Provider value={{
            categoryId,
            categoryData,
            loading: isLoading,
            error,
        }}>
            {children}
        </CategoryContext.Provider>
    );
};

export const useCategory = () => {
    const context = useContext(CategoryContext);
    if (!context) {
        throw new Error('useCategory must be used within a CategoryProvider');
    }
    return context;
};
