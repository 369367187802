import React from 'react';
import {TrueOrFalseTestView} from './TrueOrFalseTestView';
import {MultiChooseTestView} from './MultiChooseTestView';
import {MultipleAnswerTestView} from './MultipleAnswerTestView';

interface TestDetailsProps {
    testData: any;
    onBack: () => void;
}

export const TestDetailsView: React.FC<TestDetailsProps> = ({testData, onBack}) => {
    if (!testData) {
        return <div>No test data available</div>;
    }

    const renderTestContent = () => {
        const parsedData = JSON.parse(testData.test_data);
        switch (testData.test_type) {
            case 'true_or_false':
                return <TrueOrFalseTestView data={parsedData}/>;
            case 'multi_choose':
                return <MultiChooseTestView data={parsedData}/>;
            case 'multiple_answer':
                return <MultipleAnswerTestView data={parsedData}/>;
            default:
                return <div>Unsupported test type</div>;
        }
    };

    return (
        <div className="test-details-view">
            <h1 className="text-2xl font-bold mb-4">Detalii Test</h1>
            <p className="mb-2"><strong>Cod Test:</strong> {testData.test_code}</p>
            <p className="mb-2"><strong>Data:</strong> {new Date(testData.created_at).toLocaleDateString()}</p>
            <p className="mb-4"><strong>Scor:</strong> {testData.test_score}</p>

            <button
                onClick={onBack}
                className="mt-4 px-4 py-2 bg-wood-color text-white rounded hover:bg-[#A0522D]"
            >
                Înapoi la lista de teste
            </button>

            {renderTestContent()}

            <button
                onClick={onBack}
                className="mt-4 px-4 py-2 bg-wood-color text-white rounded hover:bg-[#A0522D]"
            >
                Înapoi la lista de teste
            </button>
        </div>
    );
};
