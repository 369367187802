import React, {createContext, useContext, useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {request} from '../../../Infrastructure';
import {saveEvaluareData, getEvaluareData, getProductAttributes} from '../../queries';

type TextRaspunsType = {
    id: number;
    text: string;
    raspuns: string;
};

type GrilaSubpunctType = {
    id: number;
    text: string;
    raspunsuri: {
        id: number;
        text: string;
        checked: boolean
    }[];
};

type TrueOrFalseType = {
    id: number;
    text: string;
    questions: {
        id: number;
        text: string;
        answer: boolean | null;
    }[];
};

type SubjectType = {
    text1: string;
    text2: string;
    subpunctTextRaspuns: TextRaspunsType[];
    subpuncteGrila: GrilaSubpunctType[];
    trueOrFalse: TrueOrFalseType[];
    subpuncteTextRaspuns: TextRaspunsType[];
    subpuncteGrila2: GrilaSubpunctType[];
    subpuncteTextRaspuns2: TextRaspunsType[];
    subpunctTextRaspuns3: TextRaspunsType[];
};

interface ProductData {
    id: string;
    sku: string;
    name: string;
    categories: {
        id: string;
        name: string;
        url_path: string;
    }[];
}


export type TestType = {
    product_id: number;
    test_data: SubjectType;
};

type TestStateType = {
    testData: TestType | null;
    handleSaveTest: (testData: TestType) => void;
    productData: ProductData | null;
    categories: ProductData['categories'] | null;
    loading: boolean;
    error: any;
};

const TestStateContext = createContext<TestStateType | undefined>(undefined);

export const TestRoProvider: React.FC<{ children: React.ReactNode, productId: number, sku: string }> = (
    {
        children,
        productId,
        sku
    }) => {

    const [testData, setTestData] = useState<TestType | null>(null);
    const [productData, setProductData] = useState<ProductData | null>(null);
    const categories = productData?.categories || null;

    console.log('testData', testData);

    const mutation = useMutation(
        (variables: { input: TestType }) =>
            request(saveEvaluareData, variables),
        {
            onSuccess: (data) => {
                setTestData(data.saveTestRomana);
            },
        }
    );

    const handleSaveTest = (testData: TestType) => {
        mutation.mutate({
            input: {
                product_id: productId,
                test_data: testData.test_data
            }
        });
    };

    const testRoDataQuery = useQuery({
        queryKey: ['testRoData'],
        queryFn: async () => {
            return request(getEvaluareData, { product_id: productId });
        },
        enabled: true,
        // staleTime: 300000,
    });

    const productAttributesQuery = useQuery({
        queryKey: ['productAttributes', sku],
        queryFn: async () => {
            return request(getProductAttributes, { sku, pageSize: 1, currentPage: 1 });
        },
        enabled: !!sku,
    });

    useEffect(() => {
        if (testRoDataQuery?.data) {
            setTestData(testRoDataQuery?.data?.data);
        }

        if (productAttributesQuery.data) {
            setProductData(productAttributesQuery?.data?.data?.products.items[0]);
        }

    }, [testRoDataQuery.isSuccess, productAttributesQuery.isSuccess]);

    return (
        <TestStateContext.Provider value={{
            testData,
            handleSaveTest,
            productData,
            categories,
            error: mutation.error || testRoDataQuery.error,
            loading: testRoDataQuery.isLoading || mutation.isLoading,
        }}>
            {children}
        </TestStateContext.Provider>
    );
};

export const useTestState = () => {
    const context = useContext(TestStateContext);
    if (context === undefined) {
        throw new Error("useTestState must be used within a TestRoProvider");
    }
    return context;
};
