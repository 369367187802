import React, {createContext, useState, useContext, useEffect, ReactNode} from 'react';
import {request} from '../../../Infrastructure';
import {useQuery} from "@tanstack/react-query";
import {getCmsBlocks} from '../../queries';
import useLocalStorageState from "use-local-storage-state";

interface Block {
    data: {
        cmsBlocks: {
            items: any[]
        }
    }
}

interface ThemeContextProviderValue {
    blockDataDesktop: Block | null;
    blockDataMobile: Block | null;
    loading: boolean;
    error: any;
}

const ThemeContextProvider = createContext<ThemeContextProviderValue | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [localData, setLocalData] = useLocalStorageState('footerData', {defaultValue: null});
    const timeToRefresh = 30 * 60 * 1000; // 30 minutes

    const isValidData = (data) => {
        if (!data || !data.timestamp) return false;
        const now = new Date().getTime();
        const elapsed = now - data.timestamp;
        return elapsed < timeToRefresh;
    };

    const cmsBlockQuery = useQuery({
        queryKey: ['cmsBlocks'],
        queryFn: async () => {
            return request(getCmsBlocks, {
                identifier1: "footer_info_desktop",
                identifier2: "footer_info_mobile"
            });
        },
        enabled: !localData || !isValidData(localData),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (cmsBlockQuery.data) {
            const newData = {
                blockDataDesktop: cmsBlockQuery.data.data.block1.items[0],
                blockDataMobile: cmsBlockQuery.data.data.block2.items[0],
                timestamp: new Date().getTime()
            };
            setLocalData(newData);
        }
    }, [cmsBlockQuery.isSuccess]);

    const contextValue: ThemeContextProviderValue = {
        blockDataDesktop: localData?.blockDataDesktop || null,
        blockDataMobile: localData?.blockDataMobile || null,
        loading: cmsBlockQuery.isLoading,
        error: cmsBlockQuery.error,
    };

    return (
        <ThemeContextProvider.Provider value={contextValue}>
            {children}
        </ThemeContextProvider.Provider>
    );
};

export const useThemeContextProvider = () => {
    const context = useContext(ThemeContextProvider);
    if (!context) {
        throw new Error('useThemeContextProvider must be used within a ThemeProvider');
    }
    return context;
};
