import React, { useState } from 'react';

interface StarProps {
    totalStars: number;
    starSize?: string;
    onStarSelect?: (rating: number) => void;
    isInteractive?: boolean;
}

export const Stars: React.FC<StarProps> = ({
                                        totalStars,
                                        starSize = "h-6 w-6",
                                        onStarSelect,
                                        isInteractive = false
                                    }) => {
    const [selectedStars, setSelectedStars] = useState(totalStars);

    const handleStarClick = (index: number) => {
        if (!isInteractive) return;  // Don't update if not in interactive mode

        const rating = index + 1;
        setSelectedStars(rating);

        if (onStarSelect) {
            onStarSelect(rating);
        }
    }

    return (
        <React.Fragment>
            {Array.from({length: 5}).map((_, i) => (
                <svg
                    key={i}
                    onClick={() => handleStarClick(i)}
                    className={`${starSize} cursor-pointer ${i < (isInteractive ? selectedStars : totalStars) ? `fill-current text-peru-color` : `fill-current text-gray-300`}`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173
                                6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927
                                0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83
                                4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>
            ))}
        </React.Fragment>
    );
}
