import React from "react";
import {ProductAttributeProvider} from "../../state";
import {client} from "../../../Infrastructure/models/client";
import {QueryClientProvider} from "@tanstack/react-query";
import {PageTitle} from "./ProductInfo/PageTitle";
import {useWindowSize} from "../../../../general";
import {ShortDescription} from "./ProductInfo/ShortDescription";
import {InfoReviews} from "./ProductInfo/InfoReviews";
import {AddToFavoritesButton} from "./AddToCart/AddToFavoritesButton";
import {CustomAttributes} from "./ProductInfo/CustomAttributes";
import {CheckoutProvider} from "../../../Checkout";
import {AddToCartButton} from "./AddToCart/AddToCartButton";
import {PriceInfo} from "./ProductInfo/PriceInfo";

interface ProductSku {
    productSku: string;
}

const queryClient = client();

export const ProductInfo: React.FC<ProductSku> = ({productSku}) => {
    const {isMobile} = useWindowSize();

    return (
        <QueryClientProvider client={queryClient}>
            <CheckoutProvider>
                <ProductAttributeProvider productSku={productSku} config={{}}>
                    <div className={'rounded-xl shadow-round-shadow p-3 md:p-6 mb-5 md:mb-15'}>
                        <div className={'p-5 flex flex-col justify-start gap-3'}>
                            <PageTitle/>
                            <p className={'react-css font-font-p text-base md:text-xl text-center md:text-start md:text-start'}>
                                {'Cod produs: '}{productSku}
                            </p>
                            <CustomAttributes/>
                        </div>

                        <div className={'p-5 flex flex-row items-center justify-center md:justify-start gap-10'}>
                            <InfoReviews/>
                            <AddToFavoritesButton productSku={productSku}/>
                        </div>
                        <div className={'p-5 flex flex-col justify-start'}>
                            <ShortDescription/>
                        </div>
                        <div className={'p-5 flex flex-col justify-start'}>
                            <PriceInfo/>
                        </div>
                        <div className={'p-5 md:bg-inherit flex items-center justify-center md:justify-start gap-5'}>
                            <AddToCartButton/>
                        </div>
                    </div>
                </ProductAttributeProvider>
            </CheckoutProvider>
        </QueryClientProvider>
    );
}
