import React from 'react';

interface StatusIconProps {
    isCorrect: boolean;
    size?: 'sm' | 'md' | 'lg';
}

export const StatusIcon: React.FC<StatusIconProps> = ({ isCorrect, size = 'md' }) => {
    const sizeClasses = {
        sm: 'h-6 w-6',
        md: 'h-8 w-8',
        lg: 'h-12 w-12'
    };

    if (isCorrect) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={`${sizeClasses[size]} text-green-500`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
        );
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={`${sizeClasses[size]} text-red-500`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
        );
    }
};
