import React from 'react';
import {StatusIcon} from '../../../../../general';

interface TrueOrFalseTestViewProps {
    data: any;
}

export const TrueOrFalseTestView: React.FC<TrueOrFalseTestViewProps> = ({data}) => {
    return (
        <div
            className="true-or-false-container max-w-[800px] flex flex-col gap-3 mt-5 mb-10 md:mb-15 p-3 md:p-6 border-2 border-double border-medium-blue shadow-round-shadow rounded-xl border-x border-y">
            <h2 className="uppercase font-font-p font-semibold text-text-color text-2xl">
                Adevarat sau fals - Rezultate
            </h2>
            {data.trueOrFalse.map((item) => (
                <div key={item.id}
                     className="true-or-false-item w-full mb-6 flex flex-col items-start justify-start gap-5">
                    <p dangerouslySetInnerHTML={{__html: item.text}}
                       className="font-font-s font-normal text-text-color text-xl bg-white p-3 border-2 border-double border-medium-blue rounded-xl border-x border-y"/>
                    {item.questions.map((question) => (
                        <div key={question.id}
                             className="question w-full p-3 border-2 border-double border-medium-blue rounded-xl border-x border-y">
                            <p className="font-font-s text-lg italic font-bold">Intrebarea {question.id}</p>
                            <p dangerouslySetInnerHTML={{__html: question.text}}
                               className="font-font-s font-normal text-text-color text-xl bg-white p-3 border-2
                               border-double border-medium-blue rounded-xl border-x border-y"/>
                            <div className={'flex flex-col md:flex-row gap-3 justify-between items-center'}>
                                <div className="flex items-center mt-2">
                                    <p className="font-font-s text-lg italic font-bold mr-2">Răspunsul tău:</p>
                                    <p className="font-font-s font-normal text-text-color text-xl">
                                        {question.user_answer === true ? 'Adevărat' : question.user_answer === false ? 'Fals' : 'Nu ai răspuns'}
                                    </p>
                                </div>
                                <div className="flex items-center mt-2">
                                    <p className="font-font-s text-lg italic font-bold mr-2">Răspunsul corect:</p>
                                    <p className="font-font-s font-normal text-text-color text-xl">
                                        {question.right_answer === true ? 'Adevărat' : 'Fals'}
                                    </p>
                                </div>
                                <StatusIcon isCorrect={question.user_answer === question.right_answer} size="lg"/>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
