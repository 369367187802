import React from 'react';

export const FooterInfo = () => {
    const basePath = '/static/frontend/Hint4/theme/ro_RO/images/';

    return (
        <div className={'flex flex-row justify-around items-start gap-5'}>
            <div className={'react-css flex flex-col justify-center items-center'}>
                <p className={'font-bold text-left text-text-color font-font-s text-xl'}>{'Proceduri de soluționare alternativă a litigiilor'}</p>
                <div className={'flex flex-row gap-5'}>
                    <a className="anpc-sal" rel="nofollow"
                       href="https://anpc.ro/ce-este-sal/"
                       title="Solutionarea alternativa a litigiilor"
                       target="_blank">
                        <img className={'h-[30px]'} src={`${basePath}SAL.svg`}
                             alt="Solutionarea alternativa a litigiilor"/>
                    </a>
                    <a className="anpc-sol" rel="nofollow"
                       href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
                       title="Solutionarea online a litigiilor"
                       target="_blank">
                        <img className={'h-[30px]'} src={`${basePath}SOL.svg`}
                             alt="Solutionarea online a litigiilor"/>
                    </a>
                </div>
            </div>
        </div>
    );
};
