import React from 'react';
import { StatusIcon } from '../../../../../general';

interface MultiChooseTestViewProps {
    data: any;
}

export const MultiChooseTestView: React.FC<MultiChooseTestViewProps> = ({data}) => {
    return (
        <div className="multi-choose-container max-w-[800px] flex flex-col gap-3 mt-5 mb-10 md:mb-15 p-3 md:p-6 border-2 border-double border-medium-blue shadow-round-shadow rounded-xl border-x border-y">
            <h2 className="uppercase font-font-p font-semibold text-text-color text-2xl">
                Grila - Rezultate
            </h2>
            {data.multiChoose.map((item) => (
                <div key={item.id} className="multi-choose-item w-full mb-6 flex flex-col items-start justify-start gap-5">
                    <p dangerouslySetInnerHTML={{__html: item.text}}
                       className="font-font-s font-normal text-text-color text-xl bg-white p-3 border-2 border-double border-medium-blue rounded-xl border-x border-y"/>
                    {item.subpuncte.map((subpunct) => (
                        <div key={subpunct.id} className="subpunct w-full p-3 border-2 border-double border-medium-blue rounded-xl border-x border-y">
                            <p className="font-font-s text-lg italic font-bold">Întrebarea {subpunct.id}</p>
                            <p dangerouslySetInnerHTML={{__html: subpunct.text}}
                               className="font-font-s font-normal text-text-color text-xl bg-white p-3 border-2 border-double border-medium-blue rounded-xl border-x border-y"/>
                            <div className={'flex flex-col md:flex-row gap-3 justify-between items-center'}>
                                <div className="flex flex-col mt-2">
                                    <p className="font-font-s text-lg italic font-bold">Răspunsul tău:</p>
                                    <p className="font-font-s font-normal text-text-color text-xl">
                                        {subpunct.answer.find(a => a.id === subpunct.user_answer)?.text || 'Nu ai răspuns'}
                                    </p>
                                </div>
                                <div className="flex flex-col mt-2">
                                    <p className="font-font-s text-lg italic font-bold">Răspunsul corect:</p>
                                    <p className="font-font-s font-normal text-text-color text-xl">
                                        {subpunct.answer.find(a => a.checked)?.text || 'Nu există răspuns corect definit'}
                                    </p>
                                </div>
                                <StatusIcon isCorrect={subpunct.user_answer === subpunct.right_answer} size="lg"/>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
