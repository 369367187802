import React from 'react';
import {TextEditorConfig} from '../assets/TextEditorConfig';

export const MultipleAnswer = ({subpunct, onChange}) => {
    const handleTextChange = (content) => {
        onChange({...subpunct, text: content});
    };

    const handleSubpunctChange = (subpunctId, field, value) => {
        const updatedSubpuncte = subpunct.subpuncte.map(sp =>
            sp.id === subpunctId ? {...sp, [field]: value} : sp
        );
        onChange({...subpunct, subpuncte: updatedSubpuncte});
    };

    const handleAnswerChange = (subpunctId, answerId, value) => {
        const updatedSubpuncte = subpunct.subpuncte.map(sp =>
            sp.id === subpunctId
                ? {
                    ...sp,
                    answers: sp.answers.map(a =>
                        a.id === answerId ? {...a, words: value.split(',').map(word => word.trim())} : a
                    )
                }
                : sp
        );
        onChange({...subpunct, subpuncte: updatedSubpuncte});
    };

    const renderSubpunct = (sp) => (
        <div key={sp.id} className="flex flex-col gap-6 w-full mb-8 border-b-2 border-light-gr border-dashed">
            <p className="text-xl font-semibold">
                Intrebarea {sp.id}
            </p>
            <TextEditorConfig
                value={sp.text}
                onEditorChange={(content) => handleSubpunctChange(sp.id, 'text', content)}
                placeholder={`Introduceți textul pentru întrebarea ${sp.id}`}
            />
            {sp.answers.map(answer => (
                <div key={answer.id} className="w-full">
                    <input
                        className="border-2 border-gray-300 p-1 w-full"
                        type="text"
                        value={answer.words.join(', ')}
                        onChange={(e) => handleAnswerChange(sp.id, answer.id, e.target.value)}
                        placeholder={`Răspunsuri ${answer.id} (separate prin virgulă)`}
                    />
                </div>
            ))}
        </div>
    );

    return (
        <div className="flex flex-col gap-8 items-start justify-start w-full pb-8">
            <p className="text-2xl font-semibold">
                Introduceti textul initial
            </p>
            <TextEditorConfig
                value={subpunct.text}
                onEditorChange={handleTextChange}
                placeholder={"Introduceți textul pentru acest subpunct"}
            />
            <p className="text-2xl font-semibold">
                Intrebari
            </p>
            <div className="grid grid-cols-2 gap-10 w-full">
                {subpunct.subpuncte.map(renderSubpunct)}
            </div>
        </div>
    );
};
