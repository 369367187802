import React, {createContext, ReactNode, useContext, useEffect, useMemo, useState} from 'react';
import {request} from '../../../Infrastructure';
import {getPromoCartBlock, getPromoCartItems} from '../../queries';
import {useQuery} from "@tanstack/react-query";

interface SubCategoriesAndItems {
    children: {
        id: string;
        name: string;
        products: {
            total_count: number;
            items: {
                id: string;
                sku: string;
                name: string;
                custom_attributes?: {
                    attribute_code: string;
                    value: string;
                }[];
                url_rewrites: {
                    url: string;
                    parameters: {
                        name: string;
                        value: string;
                    }[];
                }[];
                thumbnail: { url: string; label: string; };
                small_image: { url: string; label: string; };
                price_range: {
                    minimum_price: {
                        regular_price: { value: number; currency: string };
                        final_price: { value: number; currency: string };
                        discount: { amount_off: number; percent_off: number };
                    };
                };
                special_price: string;
                special_from_date: string;
                special_to_date: string;
            }[];
        }[];
    }[];
}

interface PromoDataProvider {
    blockData: any;
    categoriesData: Record<string, SubCategoriesAndItems[]>;
    discounts: Record<number, number>;
    firstChildCategoryItems: any[];
    secondChildCategoryItems: any[];
    loading: boolean;
    error: any;
}

const PromoStateContext = createContext<PromoDataProvider | undefined>(undefined);

export const PromoStateProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [blockData, setBlockData] = useState<any | null>(null);
    const [categoriesData, setCategoriesData] = useState<Record<string, SubCategoriesAndItems[]> | null>(null);
    const [firstChildCategory, setFirstChildCategory] = useState<any | null>(null);
    const [secondChildCategory, setSecondChildCategory,] = useState<any | null>(null);

    const discounts = useMemo(() => ({
        1: 0.5,
        2: 0.3,
        3: 0.1
    }), []);

    const categoryId = '2';
    const identifier = 'cart_promo_banner1';

    const cmsBlockQuery = useQuery({
        queryKey: ['cmsBlocks'],
        queryFn: async () => {
            return request(getPromoCartBlock, {
                identifier
            });
        },
        enabled: true,
        // staleTime: 1800000,
    });

    useEffect(() => {
        if (cmsBlockQuery.data) {
            const blockData = cmsBlockQuery.data.data.cmsBlocks.items[0];
            setBlockData(blockData);
        }
    }, [cmsBlockQuery.isSuccess]);

    const categoriesDataQuery = useQuery({
        queryKey: ['categoriesData'],
        queryFn: async () => {
            return request(getPromoCartItems, {
                categoryId,
            });
        },
        // initialData: {data: {data: {categoryList: localFridayData}}},
        enabled: true,
        // staleTime: 3600,
    });

    useEffect(() => {
        //@ts-ignore
        const fetchedCategoriesData = categoriesDataQuery?.data?.data?.categoryList[0] || [];
        const firstChildCategory = fetchedCategoriesData?.children?.[0];
        const secondChildCategory = fetchedCategoriesData?.children?.[1];

        if (fetchedCategoriesData?.children?.length > 0) {
            // setLocalFridayData(fetchedCategoriesData);
            setCategoriesData(fetchedCategoriesData);
            setFirstChildCategory(firstChildCategory);
            setSecondChildCategory(secondChildCategory);
        }

    }, [categoriesDataQuery.isSuccess]);

    return (
        <PromoStateContext.Provider
            value={{
                blockData,
                categoriesData,
                discounts,
                firstChildCategoryItems: (firstChildCategory as any)?.products?.items || [],
                secondChildCategoryItems: (secondChildCategory as any)?.products?.items || [],
                loading: cmsBlockQuery.isLoading || categoriesDataQuery.isLoading,
                error: cmsBlockQuery.error || categoriesDataQuery.error
            }}
        >
            {children}
        </PromoStateContext.Provider>
    );
};

export const usePromoStateProvider = (): PromoDataProvider => {
    const context = useContext(PromoStateContext);
    if (!context) {
        throw new Error('useCombinedState must be used within a PromoStateContext');
    }
    return context;
};
