import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const DescriptionSk = () => {
    return (
        <div className={'w-full xl:w-1/2 h-96 flex flex-col justify-center gap-3 mb-3 md:mb-5'}>
            <Skeleton
                width={'100%'}
                className={'w-full xl:w-1/2 h-96'}
                baseColor="#f5f5f5"
                highlightColor="#6B8E23"
            />
        </div>
    );
};
