import React from 'react';
import {TextEditorConfig} from '../assets/TextEditorConfig';

export const MultiChoose = ({test, onChange}) => {
    const handleTestTextChange = (content) => {
        onChange({...test, text: content});
    };

    const handleSubpunctChange = (subpunctId, field, value) => {
        const updatedSubpuncte = test.subpuncte.map(sp =>
            sp.id === subpunctId ? {...sp, [field]: value} : sp
        );
        onChange({...test, subpuncte: updatedSubpuncte});
    };

    const handleAnswerChange = (subpunctId, answerId, field, value) => {
        const updatedSubpuncte = test.subpuncte.map(sp =>
            sp.id === subpunctId
                ? {
                    ...sp,
                    answer: sp.answer.map(a =>
                        a.id === answerId ? {...a, [field]: value} : a
                    )
                }
                : sp
        );
        onChange({...test, subpuncte: updatedSubpuncte});
    };

    return (
        <div
            className="flex flex-col gap-8 items-start justify-start w-full mb-8">
            <p className="text-2xl font-semibold">
                Introduceti textul initial
            </p>
            <TextEditorConfig
                value={test.text}
                onEditorChange={handleTestTextChange}
                placeholder={`Introduceți textul introductiv pentru acest test (test ${test.id})`}
            />
            <p className={'text-2xl font-semibold'}>
                Intrebari si raspunsuri
            </p>
            <div className="flex flex-wrap -mx-2 gap-10 pb-8">
                {test.subpuncte.map((subpunct, index) => (
                    <div
                        key={subpunct.id}
                        className="w-[48%] gap-4"
                    >
                        <div className="flex flex-col gap-4 items-start justify-start w-full border-b-2 border-light-gr border-dashed pb-8">
                            <p className="text-xl font-semibold">
                                Intrebarea {subpunct.id}
                            </p>
                            <TextEditorConfig
                                value={subpunct.text}
                                onEditorChange={(content) => handleSubpunctChange(subpunct.id, 'text', content)}
                                placeholder={`Introduceți textul pentru întrebarea ${subpunct.id}`}
                            />
                            {subpunct.answer.map(raspuns => (
                                <div key={raspuns.id} className="flex flex-row gap-2 items-center justify-start w-1/2">
                                    <input
                                        type="checkbox"
                                        checked={raspuns.checked}
                                        onChange={(e) => handleAnswerChange(subpunct.id, raspuns.id, 'checked', e.target.checked)}
                                    />
                                    <input
                                        className="border-2 border-gray-300 p-1 flex-grow"
                                        type="text"
                                        value={raspuns.text}
                                        onChange={(e) => handleAnswerChange(subpunct.id, raspuns.id, 'text', e.target.value)}
                                        placeholder={`Răspuns ${raspuns.id}`}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
