import React from 'react';
import {CategoryProvider} from '../../state/';
import {SubcategoryData} from "./SubcategoryData/SubcategoryData";
import {client} from "../../../Infrastructure/models/client";
import {QueryClientProvider} from "@tanstack/react-query";
import {CategoryData} from "./SubcategoryData/CategoryData";

interface CategoryId {
    categoryId: string;
}

const queryClient = client();

export const CategoryView: React.FC<CategoryId> = ({categoryId}) => {
    return (
        <QueryClientProvider client={queryClient}>
            <CategoryProvider categoryId={categoryId}>
                <CategoryData/>
                <SubcategoryData/>
            </CategoryProvider>
        </QueryClientProvider>
    );
};
