import React, {useState, useEffect} from 'react';
import {useTestDataState} from '../../../state';
import {TrueOrFalse} from "./types/TrueOrFalse";

interface Question {
    id: number;
    text: string;
    answer: boolean | null;
}

interface TrueOrFalseSubpunct {
    id: number;
    text: string;
    questions: Question[];
}

interface TrueOrFalseData {
    product_id: number;
    product_sku?: string;
    trueOrFalse: TrueOrFalseSubpunct[];
}

const createQuestion = (id: number): Question => ({
    id,
    text: '',
    answer: null
});

const initialTrueOrFalseData: TrueOrFalseData = {
    product_id: 0,
    trueOrFalse: [
        {
            id: 1,
            text: '',
            questions: Array(20).fill(null).map((_, index) => createQuestion(index + 1))
        }
    ]
};

export const TestsTrueOrFalse: React.FC<{ productId: number }> = ({productId}) => {
    const {trueOrFalseData, handleSaveTrueOrFalse, categories, loading, error, productData} = useTestDataState();
    const [localTrueOrFalseData, setLocalTrueOrFalseData] = useState<TrueOrFalseData>(initialTrueOrFalseData);

    useEffect(() => {
        if (trueOrFalseData) {
            if (trueOrFalseData.trueOrFalse) {
                setLocalTrueOrFalseData(trueOrFalseData);
            } else {
                // Dacă trueOrFalse este null, folosim datele inițiale
                setLocalTrueOrFalseData({
                    ...initialTrueOrFalseData,
                    product_id: trueOrFalseData.product_id || productId
                });
            }
        }
    }, [trueOrFalseData, productId]);

    const handleTrueOrFalseChange = (updatedSubpunct: TrueOrFalseSubpunct) => {
        setLocalTrueOrFalseData(prevData => ({
            ...prevData,
            trueOrFalse: prevData.trueOrFalse.map(item =>
                item.id === updatedSubpunct.id ? updatedSubpunct : item
            )
        }));
    };

    const handleSave = () => {
        handleSaveTrueOrFalse(localTrueOrFalseData, () => {
            window.location.reload();
        });
    };

    if (loading) return <div>Loading...</div>;
    if (error) {
        const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
        return <div>Error: {errorMessage}</div>;
    }

    return (
        <div>
            <p className={'text-4xl font-bold text-amber-800 mb-5'}>
                Teste cu adevărat sau fals
            </p>
            {localTrueOrFalseData.trueOrFalse.map(trueOrFalseItem => (
                <TrueOrFalse
                    key={trueOrFalseItem.id}
                    subpunct={trueOrFalseItem}
                    onChange={handleTrueOrFalseChange}
                />
            ))}
            <button onClick={handleSave} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-xl">
                Salvează testul
            </button>
        </div>
    );


};
