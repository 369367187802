import React from 'react';
import {usePromoStateProvider} from '../../state';

declare global {
    interface Window {
        BASE_URL: string;
    }
}

const ProductItem = ({product, discount, discountLabel}) => (
    <div
         className={'relative bg-white rounded-xl p-4 shadow mb-5 flex justify-between items-center flex-col h-auto ' +
             'hover:shadow-first-btn-shadow transition-all hover:border border-slate-400 duration-300 ease-in-out ' +
             'grid grid-row-2 p-5'}>
        {product?.stock_status === "OUT_OF_STOCK" && (
            <div
                className={'absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 z-10'}>
                <span
                    className={'react-css text-rose-700 text-2xl font-black mr-48 md:mb-48 md:mr-0'}>Produsul nu mai este in stoc.</span>
            </div>
        )}

        <p className={'absolute absolute left-0 top-0 bg-gradient-to-r from-orange-400 to-red-700 text-slate-100 m-5 p-3 text-2xl ' +
            'font-bold text-center rounded-xl'}>
            {discountLabel}
        </p>
        <a className={'row-span-1 h-[230px] flex justify-center items-center w-auto'}
           href={`${window.BASE_URL}${product.url_rewrites[0]?.url || ''}`}>
            <img src={product?.thumbnail.url} alt={product?.thumbnail.label}
                 className={'w-auto object-contain object-center h-3/4'} loading="lazy"/>
        </a>
        <div className={'row-span-1 grid grid-row-4 gap-4 h-[250px]'}>
            <a className={'h-[90px] text-black hover:underline active:text-black'}
               href={`${window.BASE_URL}${product.url_rewrites[0]?.url || ''}`}>
                <h2 className={'react-css text-2xl font-bold font-inter leading-10 mt-4 text-black'}>
                    {product?.name.length > 60 ? `${product?.name.substring(0, 60)}...` : product?.name}
                </h2>
            </a>
            <div className={''}></div>
            <div className={'mt-2 h-[60px]'}>
                <div>
                    <p className={'react-css text-xl font-semibold line-through'}>
                        {parseFloat(String(product?.price_range?.minimum_price?.regular_price.value)).toFixed(2)} RON
                    </p>
                    <p className={'react-css text-3xl font-bold text-red-500'}>
                        {parseFloat(String(product?.price_range?.minimum_price?.regular_price.value * discount)).toFixed(2)} RON
                    </p>
                </div>
            </div>

            <button
                className={'h-[50px] bg-main-orange focus:outline-none focus:bg-main-orange active:bg-main-orange ' +
                    'text-black text-2xl font-inter font-bold p-5 rounded-xl w-4/5 md:w-auto ' +
                    'hover:bg-main-orange hover:text-white transition-all duration-300 ease-in-out'}
                onClick={() => window.location.href = `${window.BASE_URL}${product?.url_rewrites[0]?.url || ''}`}
            >
                Vezi detalii
            </button>
        </div>
    </div>
);

export const ItemsByDiscount: React.FC<{ level: number }> = ({level}) => {
    const {
        categoriesData,
        loading,
        discounts,
        firstChildCategoryItems,
        secondChildCategoryItems
    } = usePromoStateProvider();

    if (!categoriesData) {
        return null;
    }

    if (loading)
        return (
            <div
                className={'fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-20 z-50'}>
                <span className={'text-white text-4xl font-bold'}>Loading...</span>
            </div>
        );

    let firstChildDiscount = discounts[level];
    let secondChildDiscount = discounts[level];

    if (level === 1) {
        firstChildDiscount = discounts[2]; //70%
    }

    if (level === 2) {
        firstChildDiscount = discounts[3]; //90%
        secondChildDiscount = discounts[1]; //50%
    }

    const getDiscountLabel = (discount) => {
        if (discount === discounts[2]) {
            return "Pana la 70% reducere in cos!";
        } else if (discount === discounts[3]) {
            return "Pana la 90% reducere in cos!";
        } else {
            return "Pana la 50% reducere in cos!";
        }
    };

    return (
        <div className={'grid grid-cols-2 md:grid-cols-4 gap-6 mt-5 mb-5'}>
            {firstChildCategoryItems.map((product) => (
                <ProductItem
                    key={product.id}
                    product={product}
                    discount={firstChildDiscount}
                    discountLabel={getDiscountLabel(firstChildDiscount)}/>
            ))}
            {level === 2 && secondChildCategoryItems.map((product) => (
                <ProductItem
                    key={product.id}
                    product={product}
                    discount={secondChildDiscount}
                    discountLabel={getDiscountLabel(secondChildDiscount)}/>
            ))}
        </div>
    );
};

