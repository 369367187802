import React, {useMemo} from 'react';
import {useProductAttributes} from '../../../state';
import {DescriptionSk} from "../Skeleton/DescriptionSk";
import {AnimatedLoginPrompt} from "./assets/AnimatedLoginPrompt";
import {SubscriptionAccessDenied} from "./assets/SubscriptionAccessDenied";

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const Description = () => {
    const {
        productDescription,
        loading,
        isLecturesCategory,
        isInFreeItemsCategory,
        testData
    } = useProductAttributes();

    const decodedDescription = useMemo(() => {
        return productDescription ? decodeHtml(productDescription) : null;
    }, [productDescription]);

    if (loading) {
        return <DescriptionSk/>;
    }

    if (!isLecturesCategory || !productDescription) {
        return null;
    }

    if (isInFreeItemsCategory) {
        return (
            <div className={'relative rounded-xl shadow-round-shadow md:mb-15 mb-10 p-3 md:pt-10 md:p-6'}
                 id={'productDescription'}>
                <div
                    className={' p-5 font-font-s text-text-color text-lg'}
                    dangerouslySetInnerHTML={{__html: decodedDescription}}>
                </div>
            </div>
        );
    }

    if (testData === 'userNotAuthenticated') {
        return <AnimatedLoginPrompt/>;
    }

    if (testData === 'noSubscriptionAccess') {
        return <SubscriptionAccessDenied/>;
    }

    return (
        <div className={'relative rounded-xl shadow-round-shadow md:mb-15 mb-10 p-3 md:pt-10 md:p-6'}
             id={'productDescription'}>
            <div
                className={' p-5 font-font-s text-text-color text-lg'}
                dangerouslySetInnerHTML={{__html: decodedDescription}}>
            </div>
        </div>
    );
};
