import React from "react";
import {Blocks} from "./assets/Blocks";
import {ThemeProvider} from "../../state";
import {client} from "../../../Infrastructure/models/client";
import {QueryClientProvider} from "@tanstack/react-query";
import {FooterInfo} from "./assets/FooterInfo";
import {FooterLinks} from "./assets/FooterLinks";
import {SocialLinks} from "./assets/SocialLinks";

const queryClient = client();

export const FooterContainer = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider>
                <div className={'flex flex-col gap-10'}>
                    <div className={'w-full flex flex-row justify-start items-start'}>
                        <FooterLinks/>
                    </div>
                    <div className={'w-full flex flex-col gap-10'}>
                        <Blocks/>
                        <FooterInfo/>
                        <SocialLinks/>
                    </div>
                </div>
            </ThemeProvider>
        </QueryClientProvider>
    )
}
