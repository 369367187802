import React from 'react';
import {useCategoriesAndProductsContextProvider} from '../../state';

function decodeHtml(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const Blocks = () => {
    const {blockData1, blockData2, loading} = useCategoriesAndProductsContextProvider();

    const renderBlockContent = (blockData) => {
        if (!blockData) return null;
        return <div dangerouslySetInnerHTML={{__html: decodeHtml(blockData?.content)}}></div>;
    };

    return (
        <div className={'mb-12'}>
            {renderBlockContent(blockData1)}
            {renderBlockContent(blockData2)}
        </div>
    );
};
