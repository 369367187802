import React, {useState, useEffect} from 'react';
import {useTestDataState} from '../../../state';
import {MultipleAnswer} from "./types/MultipleAnswer";

interface Answer {
    id: number;
    words: string[];
}

interface Subpunct {
    id: number;
    text: string;
    answers: [Answer, Answer];
}

interface MultipleAnswerSubpunct {
    id: number;
    text: string;
    subpuncte: Subpunct[];
}

interface MultipleAnswerData {
    product_id: number;
    product_sku?: string;
    multipleAnswer: MultipleAnswerSubpunct[];
}

const createSubpunct = (id: number): Subpunct => ({
    id,
    text: '',
    answers: [
        {id: 1, words: []},
        {id: 2, words: []}
    ]
});

const initialMultipleAnswerData: MultipleAnswerData = {
    product_id: 0,
    multipleAnswer: [
        {
            id: 1,
            text: '',
            subpuncte: Array(20).fill(null).map((_, index) => createSubpunct(index + 1))
        }
    ]
};

export const TestsMultipleAnswer: React.FC<{ productId: number }> = ({productId}) => {
    const {multipleAnswerData, handleSaveMultipleAnswer, categories, loading, error} = useTestDataState();
    const [localMultipleAnswerData, setLocalMultipleAnswerData] = useState<MultipleAnswerData>(initialMultipleAnswerData);

    console.log('multipleAnswerData', multipleAnswerData);
    console.log('localMultipleAnswerData', localMultipleAnswerData);

    useEffect(() => {
        if (multipleAnswerData) {
            if (multipleAnswerData.multipleAnswer) {
                setLocalMultipleAnswerData(multipleAnswerData);
            } else {
                // Dacă multiAnswer este null, folosim datele inițiale cu product_id corect
                setLocalMultipleAnswerData({
                    ...initialMultipleAnswerData,
                    product_id: multipleAnswerData.product_id || productId
                });
            }
        }
    }, [multipleAnswerData, productId]);

    const handleMultipleAnswerChange = (updatedSubpunct: MultipleAnswerSubpunct) => {
        setLocalMultipleAnswerData(prevData => ({
            ...prevData,
            multipleAnswer: prevData.multipleAnswer.map(item =>
                item.id === updatedSubpunct.id ? updatedSubpunct : item
            )
        }));
    };

    const handleSave = () => {
        handleSaveMultipleAnswer(localMultipleAnswerData, () => {
            // window.location.reload();
        });
    };

    if (loading) return <div>Loading...</div>;
    if (error) {
        const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
        return <div>Error: {errorMessage}</div>;
    }

    return (
        <div>
            <p className={'text-4xl font-bold text-amber-800 mb-5'}>
                Teste cu răspunsuri multiple
            </p>
            {localMultipleAnswerData.multipleAnswer.map(multipleAnswerItem => (
                <MultipleAnswer
                    key={multipleAnswerItem.id}
                    subpunct={multipleAnswerItem}
                    onChange={handleMultipleAnswerChange}
                />
            ))}
            <button onClick={handleSave} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-xl">
                Salvează testul
            </button>
        </div>
    );
}
