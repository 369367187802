import React from "react";
import {useProductAttributes} from "../../../state";
import {PageTitleSk} from "../Skeleton/PageTitleSk";

export const PageTitle = () => {
    const {productName, loading} = useProductAttributes() || {};

    if (loading) {
        return <PageTitleSk />;
    }

    return (
        <div className="flex flex-col justify-start gap-1">
            <h1 className="react-css font-font-p text-2xl md:text-4xl leading-5 font-bold text-custom-black text-center md:text-left md:text-left">
                {productName}
            </h1>
        </div>
    );
}
