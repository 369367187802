import React from 'react';
import {usePromoStateProvider} from '../../state';

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const CartPromoCmsBlock = () => {
    const {blockData} = usePromoStateProvider();

    if (!blockData) return null;

    return (
        <div className={'mb-12'}>
            <div dangerouslySetInnerHTML={{__html: decodeHtml(blockData.content)}}></div>
        </div>
    );
};
