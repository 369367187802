import React, {useState} from 'react';
import {Stars} from "../../../../../general";
import {useProductAttributes} from '../../../state';
import {useFormContext} from 'react-hook-form';
import {ErrorMessage} from "@hookform/error-message";
import {FieldError} from 'react-hook-form';

export const ReviewFormInner = ({productSku}) => {
    const {productAttributes, productName, handleAddReview, neededRating} = useProductAttributes() || {};

    if (!productAttributes) return null;

    const {
        register,
        formState: {errors},
        setError,
        clearErrors,
        handleSubmit
    } = useFormContext();

    const [rating, setRating] = useState(0);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const validateRating = () => {
        if (rating === 0) {
            setError('starRating', {
                type: 'manual',
                message: 'Te rugam sa selectezi un rating!'
            });
            return false;
        }
        clearErrors('starRating');
        return true;
    };

    const ratingId = neededRating?.id

    const onSubmit = (data) => {
        if (!validateRating()) return;

        const selectedValueId = neededRating?.values[rating - 1]?.value_id;

        const dataObj = {
            sku: productSku,
            nickname: data.nickname,
            summary: data.summary,
            text: data.text,
            ratings: [
                {
                    id: ratingId,
                    value_id: selectedValueId
                }
            ]
        };
        handleAddReview(dataObj);
        setFormSubmitted(true);
        setIsModalOpen(true);
    }

    return (
        <div className={'react-css relative mb-10 md:mb-15 w-full '} id={'review_form_react_app'}>
            {!formSubmitted && (
                <div
                    className={`react-css rounded-xl flex items-center justify-center `}>
                    <form
                        className={'react-css bg-cards-bg p-3 md:p-6 block mb-6 mt-6 md:mt-0 shadow-round-shadow rounded-xl w-full md:w-1/2 ' +
                            'border-2 border-double border-medium-blue rounded-xl border-x border-y'}
                        onSubmit={handleSubmit(onSubmit)}>
                        <div className={'react-css flex flex-col items-start justify-start mb-6 gap-2'}>
                            <p className={'react-css text-custom-black font-font-s text-lg hover:text-current'}>
                                {'Scrie-ne parerea ta:'}
                            </p>
                        </div>
                        <div className={'react-css flex flex-col items-start justify-start mb-7'}>
                        <p
                            className={'react-css font-font-p font-bold text-custom-black text-lg'}>{'Cate stele meritam? 😃'}
                        </p>
                            <div
                                className={'react-css flex flex-row items-center justify-start gap-1 text-custom-black'}>
                                <Stars
                                    totalStars={0}
                                    starSize={'h-12 w-12'}
                                    isInteractive
                                    onStarSelect={(selectedRating) => {
                                        setRating(selectedRating);
                                        if (errors.starRating) {
                                            clearErrors('starRating');
                                        }
                                        console.log('selectedRating', selectedRating)
                                    }}
                                />
                                {errors.starRating && <span
                                    className={'error-message text-red-500 text-base'}>{(errors.starRating as FieldError).message}</span>}

                            </div>
                        </div>
                        <div className={'react-css flex flex-col items-start justify-start mb-6 gap-2'}>
                            <div
                                className={'react-css flex flex-col items-start md:flex-row md:items-center justify-between gap-5 w-full mb-6'}>
                                <div className={'react-css flex flex-col items-start justify-start gap-2 w-full'}>
                                    <p className={'react-css text-custom-black font-font-p text-lg hover:text-current'}>
                                        {'Nume utilizator: '}
                                    </p>
                                    <input
                                        {...register('nickname', {
                                            required: "Nume utilizator este obligatoriu",
                                            maxLength: {
                                                value: 30,
                                                message: "Te rogam sa introduci mai puntin de 30 caractere."
                                            }
                                        })}
                                        placeholder="Numele utilizator"
                                        className={'react-css text-custom-black font-font-s font-normal text-left text-base ' +
                                            'rounded-xl border border-gray-400 w-full h-12 p-4'}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="nickname"
                                        render={({message}) => <span
                                            className={'error-message text-red-500 text-base'}>{message}</span>}
                                    />
                                </div>
                            </div>
                            <div className={'react-css flex flex-col items-start justify-start gap-2 w-full mb-6'}>
                                <p className={'react-css text-custom-black font-font-p text-lg  hover:text-current'}>
                                    {'Titlu evaluare: '}
                                </p>
                                <input
                                    {...register('summary', {
                                        required: "Acest camp este obligatoriu",
                                        maxLength: {
                                            value: 50,
                                            message: "Te rogam sa introduci mai puntin de 50 caractere."
                                        }
                                    })}
                                    placeholder="Titlul evaluarii"
                                    className={'react-css text-custom-black font-font-p font-normal text-left text-base ' +
                                        'rounded-xl border border-gray-400 w-full h-12 p-4'}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="summary"
                                    render={({message}) => <span
                                        className={'error-message text-red-500 text-base'}>{message}</span>}
                                />
                            </div>
                            <div className={'react-css flex flex-col items-start justify-start gap-2 w-full mb-6'}>
                                <p className={'react-css text-custom-black font-font-p text-lg hover:text-current'}>
                                    {'Evaluare: '}
                                </p>
                                <textarea
                                    {...register('text', {
                                        maxLength: {
                                            value: 1000,
                                            message: "Te rogam sa introduci mai puntin de 1000 caractere."
                                        }
                                    })}
                                    placeholder="Scrie-ne parerea ta"
                                    rows={6}
                                    className={'react-css text-custom-black font-font-s font-normal text-left ' +
                                        'text-base rounded-xl border border-gray-400 w-full h-auto min-w-[150px] p-4'}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="text"
                                    render={({message}) => <span
                                        className={'error-message text-red-500 text-base'}>{message}</span>}
                                />
                            </div>
                        </div>

                        {/*Submit button*/}
                        <div className={'react-css flex items-center justify-center align-center gap-2 w-full mb-6'}>
                            <button type="submit"
                                    className={'bg-olive-color hover:bg-olive-color hover:text-white text-white font-bold py-2 px-4 ' +
                                        'focus:bg-olive-color active:olive-color focus:text-white active:text-white ' +
                                        'rounded-xl flex items-center transition duration-300 ease-in-out transform hover:scale-105'}>
                                {'Trimite evaluarea'}
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {isModalOpen && (
                <div className={'react-css fixed top-0 left-0 w-full h-full flex items-center justify-center z-99'}>
                    <div
                        className={'react-css bg-wood-color p-6 rounded-xl shadow-custom-box-shadow w-96 p-6 absolute top-24'}>
                        <h2 className={'react-css font-font-s text-white text-base mb-4'}>{'Multumim!'}</h2>
                        <p className={'text-white font-font-s'}>{'Evaluarea dv. a fost trimisa!'}</p>
                        <p className={'text-white font-font-s'}>{'Va fi aprobata si publicata in cel mai scurt timp posibil!'}</p>
                        <button
                            onClick={() => setIsModalOpen(false)}
                            className={'react-css mt-4 px-4 py-2 rounded-xl bg-btn-color hover:bg-btn-hover ' +
                                'text-base font-font-s text-center text-white focus:text-white hover:text-white'}
                            type="button"
                        >
                            Inchide
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
