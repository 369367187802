import React, {useEffect, useState} from 'react';
import {CartPromoCmsBlock} from './CartPromoCmsBlock';
import {ItemsByDiscount} from './ItemsByDiscount';
import {ProgressBar} from "./ProgressBar";
import {PromoStateProvider} from "../../state";
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";

const queryClient = client()

export const PromoCart: React.FC = () => {
    const promotionLevels = [500, 1000];
    const [showLevel, setShowLevel] = useState(1);
    const [total, setTotal] = useState(0);

    const getLocalStorageTotal = () => {
        const localStorageKey = JSON.parse(localStorage.getItem('mage-cache-storage') || '{}');
        return localStorageKey['cart']?.['subtotalAmount'] ?? 0;
    };

    const updateShowLevel = () => {
        const currentTotal = getLocalStorageTotal();
        setTotal(currentTotal);
        setShowLevel(currentTotal >= promotionLevels[1] ? 2 : 1);
    };

    useEffect(() => {
        const handleStorageChange = (e: StorageEvent) => {
            if (e.key === 'mage-cache-storage') {
                updateShowLevel();
            }
        };
        window.addEventListener('storage', handleStorageChange);

        const intervalId = setInterval(() => {
            updateShowLevel();
        }, 1000);

        updateShowLevel();

        return () => {
            window.removeEventListener('storage', handleStorageChange);
            clearInterval(intervalId);
        };
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <PromoStateProvider>
                <div className={''}>
                    <ProgressBar promotionLevels={promotionLevels} currentTotal={total}/>
                    <div
                        className={'flex flex-col gap-6 justify-center items-center w-full bg-custom-gradient p-5 mt-5'}>
                        <CartPromoCmsBlock/>
                    </div>
                    <div className={'flex flex-col gap-6 justify-center items-center w-full bg-custom-gradient p-5'}>
                        <ItemsByDiscount level={showLevel}/>
                    </div>
                </div>
            </PromoStateProvider>
        </QueryClientProvider>
    );
};
