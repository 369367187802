import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface SlideData {
    src: string;
    alt: string;
    title: string;
    link: string;
}

interface ImageSliderProps {
    slides: SlideData[];
}

export const ImageSlider: React.FC<ImageSliderProps> = ({slides}) => {
    return (
        <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={30}
            slidesPerView={1}
            navigation
            pagination={{clickable: true}}
            loop={true}
        >
            {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                    <div className={'relative'}>
                        <span className={'font-font-p text-3xl md:text-6xl md:leading-loose absolute top-12 right-12 max-w-[70%] md:max-w-[40%] ' +
                            'text-center bg-opacity-50 font-black tracking-widest drop-shadow-2xl'}>
                           {slide.title}
                        </span>
                        <a href={slide.link} title={slide.title}>
                            <img src={slide.src} alt={slide.alt} className="w-full h-auto rounded-lg"/>
                        </a>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};
