import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const PageTitleSk = () => {
    return (
        <div className={'w-full h-16 flex flex-col justify-center xl:justify-start gap-3 mb-3 md:mb-5'}>
            <Skeleton
                width={'100%'}
                className={'w-full h-16'}
                baseColor="#f5f5f5"
                highlightColor="#6B8E23"
            />
        </div>
    );
};
