import React from 'react';
import {useThemeContextProvider} from '../../../state';
import {useWindowSize} from '../../../../../general';

function decodeHtml(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const Blocks = () => {
    const {blockDataDesktop, blockDataMobile} = useThemeContextProvider();
    const {isMobile} = useWindowSize();

    const renderBlockContent = (blockData) => {
        if (!blockData) return null;
        return <div dangerouslySetInnerHTML={{__html: decodeHtml(blockData.content)}}></div>;
    };

    return (
        <div className={'mb-0 xl:mb-12 mt-3 xl:mt-0'}>
            <div className={'desktop-footer'}>
                {renderBlockContent(blockDataDesktop)}
            </div>

            {isMobile && blockDataMobile &&
                <div className={'desktop-mobile xl:hidden'}>
                    {renderBlockContent(blockDataMobile)}
                </div>
            }
        </div>
    );
};
