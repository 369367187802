import React from 'react';
import { AlertCircle } from 'lucide-react';
import { Translate } from './Translate';

export const ErrorMessage = ({ message }) => {
    return (
        <div className="bg-light-blue bg-opacity-10 border-4 border-btn-color rounded-xl p-4 my-4">
            <div className="flex items-center">
                <AlertCircle className="text-btn-color mr-3" size={24} />
                <div>
                    <p className="font-font-s text-custom-black font-semibold">
                        Eroare
                    </p>
                    <p className="font-font-s text-medium-blue mt-1">
                        <Translate message={message} />
                    </p>
                </div>
            </div>
        </div>
    );
};
