import React, { useState, useEffect } from 'react';
import {useWindowSize} from '../../../../general';

export const InConstruction = () => {
    const {isMobile, isTablet} = useWindowSize();
    const [blockPosition, setBlockPosition] = useState(0);
    const basePath = '/static/frontend/Hint4/theme/ro_RO/images/';
    const [showCreeper, setShowCreeper] = useState(false);

    useEffect(() => {
        const blockInterval = setInterval(() => {
            setBlockPosition(prev => (prev + 1) % 5);
        }, 2000);

        const creeperInterval = setInterval(() => {
            setShowCreeper(prev => !prev);
        }, 5000);

        return () => {
            clearInterval(blockInterval);
            clearInterval(creeperInterval);
        };
    }, []);
    useEffect(() => {
        const blockInterval = setInterval(() => {
            setBlockPosition(prev => (prev + 1) % 5);
        }, 2000);

        return () => {
            clearInterval(blockInterval);
        };
    }, []);

    const Block = ({ type, position }) => (
        <div className={`w-20 h-20 md:w-40 md:h-40 absolute bottom-0 transition-all duration-300 ease-bounce ${position === blockPosition ? 'animate-bounce' : ''}`}
             style={{
                 left: isMobile
                     ? `${position * 20}%`
                     : `calc(10% + ${position * 16}%)`
             }}>
            <img src={`${basePath}${type}`} alt={`${type} block`} className="w-full h-full" />
        </div>
    );

    return (
        <div className={'min-h-[250px] xl:min-h-[500px]flex flex-col items-center justify-center p-4 font-minecraft'}>
            <div className={'text-center mb-8'}>
                <h1 className="text-4xl font-bold text-text-color mb-4 font-font-p">
                    {'Construim ceva epic!'}
                </h1>
                <p className={'text-xl text-text-color mt-2 font-font-s'}>
                    {'Reveniți mai târziu pentru a vedea ce am construit!'}
                </p>
                <p className={'text-xl text-text-color font-font-s'}>
                    {'Villagerii noștri lucrează din greu să termine această pagină.'}
                </p>
            </div>

            <div className="relative w-full h-[400px] rounded-lg overflow-hidden"
                 style={{
                     backgroundImage: `url(${basePath}beach2.png)`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'center'
                 }}>
                <div className={'absolute bottom-0 left-0 w-full h-40 bg-brown-600 bg-opacity-50'}></div>
                <Block type="dirtnb.png" position={0}/>
                <Block type="stonenb.png" position={1}/>
                <Block type="woodnb.png" position={2}/>
                <Block type="ironnb.png" position={3}/>
                <Block type="diamondnb.png" position={4}/>

                {showCreeper && (
                    <div
                        className={'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-pulse'}>
                        <img src={`${basePath}creepernb.png`} alt="Creeper" className="w-60 h-60"/>
                    </div>
                )}
            </div>

            <div className={'mt-8 text-center'}>
                <p className={'text-lg text-text-color font-font-s'}>
                    {'Progres construcție: 0% 😊 😊 😊'}
                </p>
            </div>
        </div>
    );
};
