import React from 'react';
import { StatusIcon } from '../../../../../general/assets/StatusIcon';

interface MultipleAnswerTestViewProps {
    data: any;
}

const normalizeAnswer = (answer: string): string => {
    return answer
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim();
};

const isAnswerCorrect = (userAnswer: string, correctAnswers: string[]): boolean => {
    const normalizedUserAnswer = normalizeAnswer(userAnswer);
    return correctAnswers.some(answer =>
        normalizeAnswer(answer).split(',').some(part =>
            normalizeAnswer(part.trim()) === normalizedUserAnswer
        )
    );
};

export const MultipleAnswerTestView: React.FC<MultipleAnswerTestViewProps> = ({data}) => {
    return (
        <div className="multiple-answer-container max-w-[800px] flex flex-col gap-3 mt-5 mb-10 md:mb-15 p-3 md:p-6 border-2 border-double border-medium-blue shadow-round-shadow rounded-xl border-x border-y">
            <h2 className="uppercase font-font-p font-semibold text-text-color text-2xl">
                Răspunsuri multiple - Rezultate
            </h2>
            {data.multipleAnswer.map((item) => (
                <div key={item.id} className="multiple-answer-item w-full mb-6 flex flex-col items-start justify-start gap-5">
                    <p dangerouslySetInnerHTML={{__html: item.text}}
                       className="font-font-s font-normal text-text-color text-xl bg-white p-3 border-2 border-double border-medium-blue rounded-xl border-x border-y"/>
                    {item.subpuncte.map((subpunct) => (
                        <div key={subpunct.id}
                             className="subpunct w-full p-3 border-2 border-double border-medium-blue rounded-xl border-x border-y">
                            <p className="font-font-s text-lg italic font-bold">Întrebarea {subpunct.id}</p>
                            <p dangerouslySetInnerHTML={{__html: subpunct.text}}
                               className="font-font-s font-normal text-text-color text-xl bg-white p-3 border-2 border-double border-medium-blue rounded-xl border-x border-y"/>
                            {subpunct.answers.map((answer) => (
                                <div key={answer.id} className="mt-2 flex flex-col md:flex-row justify-between items-start md:items-center">
                                    <div>
                                        <p className="font-font-s text-lg italic font-bold">Răspunsul tău {answer.id}:</p>
                                        <p className="font-font-s font-normal text-text-color text-xl">{answer.user_answer}</p>
                                    </div>
                                    <div>
                                        <p className="font-font-s text-lg italic font-bold mt-1">Răspunsuri corecte posibile:</p>
                                        <p className="font-font-s font-normal text-text-color text-xl">
                                            {Array.isArray(answer.right_answer)
                                                ? answer.right_answer.join(', ')
                                                : answer.right_answer}
                                        </p>
                                    </div>
                                    <StatusIcon
                                        isCorrect={isAnswerCorrect(
                                            answer.user_answer,
                                            Array.isArray(answer.right_answer)
                                                ? answer.right_answer
                                                : [answer.right_answer]
                                        )}
                                        size="lg"
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
