import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

export const editorConfig = {
    height: 350,
    width: '100%',
    plugins: [
        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
        'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount'
    ],
    toolbar: 'undo redo | blocks | formatselect | fontsizeselect | bold italic underline strikethrough | ' +
        'alignleft aligncenter alignright alignjustify | outdent indent | ' +
        'numlist bullist | forecolor backcolor removeformat | ' +
        'preview fullscreen ',
    content_style: `
        body {
            font-family: 'Times New Roman', Times, serif;
            font-size: 16px;
            padding: 0.25rem;
            width: 21cm;
            min-height: 29.7cm;
            padding: 2cm;
            margin: 1cm auto;
            border: 1px solid #D3D3D3;
            border-radius: 5px;
            background: white;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        }
    `,
    content_css: 'document',
    menubar: false,
};

interface ConfiguredEditorProps {
    value: string;
    onEditorChange: (content: string) => void;
    placeholder?: string;
}


export const TextEditorConfig: React.FC<ConfiguredEditorProps> = ({ value, onEditorChange, placeholder }) => {
    return (
        <Editor
            apiKey="afzmphfbf7rs7hlg4dq58vee1piojqo8yywz8h8xy4wij1xn"
            init={{
                ...editorConfig,
                placeholder: placeholder
            }}
            value={value}
            onEditorChange={onEditorChange}
        />
    );
};
