import React from 'react';
import {useTestDataState} from '../../../state';
import {TestsTrueOrFalse} from './TestsTrueOrFalse';
import {TestsMultiChoose} from './TestsMultiChoose';
import {TestsMultipleAnswer} from "./TestsMultipleAnswer";

export const TestSelector: React.FC<{ productId: number }> = ({ productId }) => {
    const { categories, loading, error } = useTestDataState();

    if (loading) return <div>Loading...</div>;
    if (error) {
        const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
        return <div>Error: {errorMessage}</div>;
    }

    const isTrueOrFalseCategory = categories?.some(
        category => category.name === "Teste Adevarat-Fals"
    );

    const isMultiChooseCategory = categories?.some(
        category => category.name === "Teste Grila"
    );

    const isMultipleAnswerCategory = categories?.some(
        category => category.name === "Teste cu Raspunsuri Multiple"
    );

    return (
        <div>
            {isTrueOrFalseCategory && <TestsTrueOrFalse productId={productId} />}
            {isMultiChooseCategory && <TestsMultiChoose productId={productId} />}
            {isMultipleAnswerCategory && <TestsMultipleAnswer productId={productId} />}
            {!isTrueOrFalseCategory && !isMultiChooseCategory && !isMultipleAnswerCategory &&
                <p>Nu există teste disponibile pentru acest produs.</p>}
        </div>
    );
};
