import React, {useState, useEffect} from 'react';
import {useTestDataState} from '../../../state';
import {MultiChoose} from "./types/MultiChoose";

interface Answer {
    id: number;
    text: string;
    checked: boolean;
}

interface MultiChooseSubpunct {
    id: number;
    text: string;
    subpuncte: {
        id: number;
        text: string;
        answer: Answer[];
    }[];
}

interface MultiChooseData {
    product_id: number;
    product_sku?: string;
    multiChoose: MultiChooseSubpunct[];
}

const createSubpunct = (id: number): MultiChooseSubpunct['subpuncte'][0] => ({
    id,
    text: '',
    answer: Array(4).fill(null).map((_, index) => ({
        id: index + 1,
        text: '',
        checked: false
    }))
});

const initialMultiChooseData: MultiChooseData = {
    product_id: 0,
    multiChoose: [
        {
            id: 1,
            text: '',
            subpuncte: Array(20).fill(null).map((_, index) => createSubpunct(index + 1))
        }
    ]
};

export const TestsMultiChoose: React.FC<{ productId: number }> = ({productId}) => {
    const {multiChooseData, handleSaveMultiChoose, categories, loading, error, productData} = useTestDataState();
    const [localMultiChooseData, setLocalMultiChooseData] = useState<MultiChooseData>(initialMultiChooseData);

    useEffect(() => {
        if (multiChooseData) {
            if (multiChooseData.multiChoose) {
                setLocalMultiChooseData(multiChooseData);
            } else {
                // Dacă multiChoose este null, folosim datele inițiale cu product_id corect
                setLocalMultiChooseData({
                    ...initialMultiChooseData,
                    product_id: multiChooseData.product_id || productId
                });
            }
        }
    }, [multiChooseData, productId]);

    const handleMultiChooseChange = (updatedSubpunct: MultiChooseSubpunct) => {
        setLocalMultiChooseData(prevData => ({
            ...prevData,
            multiChoose: prevData.multiChoose.map(item =>
                item.id === updatedSubpunct.id ? updatedSubpunct : item
            )
        }));
    };

    const handleSave = () => {
        handleSaveMultiChoose(localMultiChooseData, () => {
            window.location.reload();
        });
    };

    if (loading) return <div>Loading...</div>;
    if (error) {
        const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
        return <div>Error: {errorMessage}</div>;
    }

    return (
        <div>
            <p className="text-4xl font-bold text-amber-800 mb-5">
                Test Grilă
            </p>
            {localMultiChooseData.multiChoose.map(multiChooseItem => (
                <MultiChoose
                    key={multiChooseItem.id}
                    test={multiChooseItem}
                    onChange={handleMultiChooseChange}
                />
            ))}
            <button onClick={handleSave} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-xl">
                Salvează testul
            </button>
        </div>
    );
};
