import React from "react";
import {useProductAttributes} from "../../../state";
import {CustomAttribSk} from "../Skeleton/CustomAttribSk";

export const CustomAttributes = () => {
    const {
        customAttributes,
        findCustomAttributeValue,
        isInFreeItemsCategory,
        createdAt,
        loading
    } = useProductAttributes() || {};

    const classLvl = findCustomAttributeValue(customAttributes, 'class_lvl');
    const difficultyLvl = findCustomAttributeValue(customAttributes, 'difficulty_lvl');
    const module_lvl = findCustomAttributeValue(customAttributes, 'module_lvl');

    const isNewProduct = (createdAt) => {
        if (!createdAt) return false;
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 3);
        return new Date(createdAt) > sixMonthsAgo;
    };

    if (loading) {
        return <CustomAttribSk/>;
    }

    return (
        <div className="flex flex-col justify-start gap-1">
            <div className="flex flex-row justify-center md:justify-start">
                {isInFreeItemsCategory && (
                    <p className="react-css font-font-p text-xl md:text-2xl leading-5 font-bold text-text-color text-center md:text-left bg-gift p-3 rounded">
                        {'Produs gratuit!'}
                    </p>
                )}
                {isNewProduct(createdAt) && (
                    <p className="react-css font-font-p text-xl md:text-2xl leading-5 font-bold text-white text-center md:text-left bg-discount p-3 rounded">
                        {'Adaugat recent!'}
                    </p>
                )}
            </div>
            {classLvl && module_lvl && (
                <p className="react-css font-font-p text-xl md:text-2xl leading-5 font-bold text-custom-black text-center md:text-left">
                Test pentru: <span>{classLvl}</span>, <span>{module_lvl}</span>
                </p>
            )}
            {difficultyLvl && (
                <p className="react-css font-font-p text-xl md:text-2xl leading-5 font-bold text-custom-black text-center md:text-left">
                    Nivel de dificultate: <span>{difficultyLvl}</span>
                </p>
            )}
        </div>
    );
};
