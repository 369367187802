import React, {useState, useEffect} from 'react';
import {AlertTriangle} from 'lucide-react';

export const PageNotFound = () => {
    const [position, setPosition] = useState({x: 50, y: 50});

    useEffect(() => {
        const moveInterval = setInterval(() => {
            setPosition(prev => ({
                x: Math.max(0, Math.min(90, prev.x + (Math.random() - 0.5) * 10)),
                y: Math.max(0, Math.min(90, prev.y + (Math.random() - 0.5) * 10))
            }));
        }, 1000);

        return () => clearInterval(moveInterval);
    }, []);

    return (
        <div className={'min-h-[250px] xl:min-h-[500px] flex flex-col items-center justify-center p-4 font-font-s relative overflow-hidden'}>
            <div
                className={'absolute text-9xl font-font-p font-bold text-wood-color animate-pulse'}
                style={{
                    left: `${position.x}%`,
                    top: `${position.y}%`,
                    transform: 'translate(-50%, -50%)',
                    transition: 'all 1s ease-in-out'
                }}
            >
                {'404'}
            </div>

            <div className={'z-10 text-center'}>
                <AlertTriangle className={'mx-auto h-24 w-24 text-yellow-500 animate-bounce'}/>
                <a
                    href="/"
                    className={'mt-40 h-[45px] font-font-p text-2xl font-bold p-4 w-auto bg-olive-color ' +
                        'hover:bg-olive-color hover:text-white text-white font-bold py-2 px-4 ' +
                        'focus:bg-olive-color active:olive-color focus:text-white active:text-white ' +
                        'rounded-xl flex items-center transition duration-300 ease-in-out transform hover:scale-105 ' +
                        'border-0 hover:border-0 flex justify-center items-center hover:no-underline'}
                >
                    {'Înapoi la pagina principală'}
                </a>
            </div>

            {[...Array(20)].map((_, i) => (
                <div
                    key={i}
                    className={'absolute bg-gray-300 rounded-full opacity-20 animate-ping'}
                    style={{
                        left: `${Math.random() * 100}%`,
                        top: `${Math.random() * 100}%`,
                        width: `${Math.random() * 20 + 10}px`,
                        height: `${Math.random() * 20 + 10}px`,
                        animationDuration: `${Math.random() * 3 + 1}s`,
                        animationDelay: `${Math.random() * 2}s`
                    }}
                />
            ))}
        </div>
    );
};
