import React from 'react';

export const TestFeedback = ({ score }) => {
    const basePath = '/static/frontend/Hint4/theme/ro_RO/images/';

    const getFeedbackContent = (score: number) => {
        if (score >= 90) {
            return {
                message: "Excelent! Ai o înțelegere foarte bună a subiectului.",
                gifUrl: `${basePath}happy-minion.jpg`,
                className: "feedback-excellent",
                advice: "Continuă să exersezi pentru a-ți menține acest nivel înalt!"
            };
        }
        if (score >= 70) {
            return {
                message: "Bine făcut! Ai cunoștințe solide, dar mai este loc de îmbunătățire.",
                gifUrl: `${basePath}happy-minion.jpg`,
                className: "feedback-good",
                advice: "Concentrează-te pe zonele în care ai ezitat pentru a-ți îmbunătăți scorul."
            };
        }
        if (score >= 50) {
            return {
                message: "Nu e rău, dar ai putea să revizuiești unele aspecte ale subiectului.",
                gifUrl: `${basePath}happy-minion.jpg`,
                className: "feedback-average",
                advice: "Încearcă să identifici zonele care ți-au dat bătăi de cap și studiază-le mai în profunzime."
            };
        }
        return {
            message: "Ar fi bine să studiezi mai mult acest subiect pentru a-ți îmbunătăți cunoștințele.",
            gifUrl: `${basePath}happy-minion.jpg`,
            className: "feedback-needs-improvement",
            advice: "Nu te descuraja! Cu puțin efort în plus, rezultatele tale se vor îmbunătăți semnificativ."
        };
    };

    const feedbackContent = getFeedbackContent(score);

    return (
        <div className={`test-feedback flex flex-col items-center justify-center gap-6 p-6 ${feedbackContent.className}`}>
            <p className="text-xl font-semibold text-center">
                {feedbackContent.message}
            </p>
            <img
                src={feedbackContent.gifUrl}
                alt={feedbackContent.className}
                className="w-72 h-72 object-cover rounded-full shadow-lg"
            />
            <p className="text-lg text-center italic">
                {feedbackContent.advice}
            </p>
        </div>
    );
};
