import React from 'react';
import {CustomerTestTable} from "./TestTypes/CustomerTestTable";
import {CustomerTestDataProvider} from "../../state";
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";

const queryClient = client();

export const CustomerTestsList = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <CustomerTestDataProvider>
                <CustomerTestTable/>
            </CustomerTestDataProvider>
        </QueryClientProvider>
    );
};
