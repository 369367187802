import React from 'react';

const errorTranslations = {
    "The requested qty is not available": "Cantitatea nu este disponibila",
    "The requested qty exceeds the maximum qty allowed in shopping cart": "Cantitatea solicitata depaseste cantitatea maxima permisa in cosul de cumparaturi.",
    "Product that you are trying to add is not available.": "Produsul pe care incerci sa il adaugi nu are cantitatea disponibila.",
    "The current customer isn't authorized.": "Nu esti logat in contul tau.",
};

const getErrorMessage = (message) => errorTranslations[message] || "";

export const Translate = ({ message }) => {
    return <div className={'text-red-500 text-xl font-font-s'}>
        {getErrorMessage(message)}
    </div>;
};
